<template>
  <div class="box" @click.stop="toDetail">
    <div class="box-name">危险区域预警</div>
    <div class="box-info">
      <div class="box-count">
        <div class="box-count-item">
          辖区危险区域数量：<span>{{ dangerAreaCount }}</span>
        </div>
        <div class="box-count-item">
          今日预警次数：<span>{{ warningCount }}</span>
        </div>
        <div class="box-count-item">
          辖区历史预警次数：<span>{{ totalCount }}</span>
        </div>
        <div class="box-count-item">
          今日进入危险区域人数：<span>{{ warningStudentNum }}</span>
        </div>
      </div>
      <div class="box-echart">
        <echartLine
          :data="latestWeekData"
          title="最近一周预警趋势："
        ></echartLine>
      </div>
    </div>

    
  </div>
</template>

<script>
import echartLine from "./echarts/line2";
import { refreshTime } from "@/config/config";
import { getStatToday, getStatLatestWeek} from "@/api/dangerousApi"


export default {
  components: {
    echartLine,
    
  },
  data() {
    return {
      dangerAreaCount: 0,
      warningCount: 0,
      totalCount: 0,
      warningStudentNum: 0,
      latestWeekData: [
        {
          name:'0',
          value:0
        },
        {
          name:'0',
          value:0
        }
      ],

      visible: false
    };
  },
  methods: {
    toDetail(){
      this.$store.state.county.dangerModelShow = true
    },
    async getStatToday() {
      const res = await getStatToday({eduId:this.$store.state.user.countyUserInfo.organizationIdStr})
      // const res = await getStatToday({eduId:'39fa74516dc842134064c7461e6c790c'})
      this.dangerAreaCount = res.dangerAreaCount ? res.dangerAreaCount : 0
      this.totalCount = res.totalCount ? res.totalCount : 0
      this.warningCount = res.warningCount ? res.warningCount : 0
      this.warningStudentNum = res.warningStudentNum ? res.warningStudentNum : 0
    },

    async getStatLatestWeek() {
      const res = await getStatLatestWeek({eduId:this.$store.state.user.countyUserInfo.organizationIdStr})
      // const res = await getStatLatestWeek({eduId:'39fa74516dc842134064c7461e6c790c'})
      this.latestWeekData = [];
      res.forEach((item) => {
          this.latestWeekData.push({
            name: item.date,
            value: item.count
        });
      })
    },

    refresh() {
      setTimeout(() => {
        this.getStatToday();
        this.getStatLatestWeek()
        this.refresh();
      }, refreshTime);
    },
  },
  
  mounted() {
    this.getStatToday();
    this.getStatLatestWeek();
    this.refresh();
  },
};
</script>

<style scoped>
* {
  color: #fff;
  font-size: 0.14rem;
}
.box {
  width: 5.04rem;
  height: 2.44rem;
  background-image: url("./img/smallBk.png");
  background-size: 100%;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}
.box-name {
  line-height: 0.27rem;
  text-align: center;
  font-weight: bold;
  font-size: 0.16rem;
}
.box-info {
  box-sizing: border-box;
  padding: 0 0.35rem;
}
.box-count {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.1rem;
}
.box-count-item {
  flex: 1 50%;
  font-size: 0.14rem;
  margin-top: 0.15rem;
}
.box-count-item span {
  color: #00f9ff;
}
.box-echart {
  height: 1.05rem;
  margin-top: 0.15rem;
  box-sizing: border-box;
}
</style>