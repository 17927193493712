import axios from './axios'
import qs from 'qs'
import {apiUrl} from '@/config/config'

//实时在校数据
export const getSchoolData = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: `${apiUrl}/district-command-center/dataStatistics/inSchoolData`,
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

//实时在校数据详情列表
export const getSchoolDataDetail = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: `${apiUrl}/district-command-center/dataStatistics/inSchoolDataDetail`,
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        })
    })
}

//校园报警
export const getSchoolWarn = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: `${apiUrl}/district-command-center/dataStatistics/alarmInfoData`,
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

//校园报警--最新报警信息
export const getSchoolNewestWarn = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: `${apiUrl}/district-command-center/dataStatistics/latestAlarmInfo`,
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        })
    })
}

//校园报警--辖区学校报警数据
export const getSchoolWarnData = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: `${apiUrl}/district-command-center/dataStatistics/AlarmInfoData`,
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        }).catch(error =>{
            reject(error)
        })
    })
}

//体温监测
export const getTemperatureData = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: `${apiUrl}/district-command-center/dataStatistics/temperatureData`,
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

//体温监测--今日体温异常名单
export const getTemperatureUnusual = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: `${apiUrl}/district-command-center/dataStatistics/temperatureUnusual`,
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}


//体温监测--今日各校体温监测情况
export const getTemperatureDataDetail = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: `${apiUrl}/district-command-center/dataStatistics/temperatureDataDetail`,
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}


//辖区基础数据
export const getBaseInfoData = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: `${apiUrl}/district-command-center/dataStatistics/baseInfoData`,
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}