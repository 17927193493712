<template>
  <div class="dangerousModal">
    <modal
      :modelSize="'max'"
      :modelShow="modelShow"
      @handelClose="close"
      @open="open"
    >
      <template slot="body">
        <div class="modal-container">
          <div class="modal-title">危险区域预警</div>
          <div class="modal-date">
           
            <el-date-picker
              v-model="date"
              @change="query"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :clearable="false"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() > Date.now() - 8.64e6;
                },
              }"
            >
              </el-date-picker>
          </div>
          <cvue-table
              :option="tableOption"
              :data="tableData"
              ref="tbl1"
              :isShowAdd="false"
              :page="page"
              :tablePage="page.currentPage"
              :pageSize="page.pageSize"
              :loading="tableLoading"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              :isShowPage="page.total > 0 && tableData.length > 0"
            >
              <!-- 操作按钮 -->
              <el-table-column
                slot="actionMenu"
                label="操作"
                align="center"
                header-align="center"
              >
                <template slot-scope="scope">
                  <div class="operation">
                    <el-button
                      type="text"
                      @click="handLook(scope.row)"
                      style="font-size: 0.16rem"
                    >
                      查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </cvue-table>
        </div>
      </template>
      </modal>

      <dangerousInfoModal :modelShow="visible" @close="visible = false" :school="school" :date="date"></dangerousInfoModal>
  </div>
</template>

<script>
import Modal from "@/components/modal/modal";
import cvueTable from "@/components/cvue-table";
import { getOrganization } from "@/api/organizationApi.js";
import { getAreaSchoolDanger } from "@/api/dangerousApi";
import dangerousInfoModal from "@/components/modal/danger/dangerousInfoModal";
import timeFormat from '@/util/timeFormat'
export default {
  name: "dangerousModal",
  components: {
    Modal,
    cvueTable,
    dangerousInfoModal
  },
  props: {
    modelShow: Boolean
  },

  computed:{
 
        userInfo(){
          return this.$store.state.user.countyUserInfo
        }
    },
  data() {
    return {
      areaId: "",
    
      date: '',
     
      visible: false,

      tableLoading: false,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 8, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50],
      },
      tableOption: {
        stripe: true,
        width: "100%",
        border: false,
        index: false,
        cloumn: [
          { prop: "schoolName", label: "学校名称", align: "center" },
          { prop: "schoolDangerRangeConfigCount", label: "危险区域数量", align: "center" },
          { prop: "todayWarningCount", label: "今日预警数量", align: "center" },
          { prop: "todayWarningStudentCount", label: "今日预警人数", align: "center" },
        ],

      
      },
      tableData: [
       
      ],
      school: null
    };
  },
  watch: {
   
  },
  methods: {
    async open() {
      this.date = timeFormat.formatting(new Date(), 'yyyy-MM-dd HH:mm:ss')
      // await this.getOrganization();
      await this.getList(1);
      
    },

    formatDate(d){
      var year = d.getFullYear
    },

    close() {
      this.$emit('close')
    },

    handLook(row) {
      this.school = row
      this.visible = true
    },
    query(){
      this.getList(1)
    },

     // 改变pagesize
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList(1)
    },
    // 改变页码
    handleCurrentChange(val) {
   
      this.getList(val)
    },

    async getList(pageNum) {
      this.page.currentPage = pageNum;

      if(!this.date){
        this.$message.error('请选择日期')
        return
      }
      
      const params = {
        eduId: this.userInfo.organizationIdStr,
        date:this.date ,
        page: this.page.currentPage,
        limit: this.page.pageSize,
        IsIncludeDelete: true
      }
      const res = await getAreaSchoolDanger(params);

      this.page.total = res.data.total
       
      this.tableData = res.data.records
     
    },

    async getOrganization() {
      const res = await getOrganization(
        this.userInfo.organizationIdStr
      );
      this.areaId = res.area.id;
    },

   
  },
  mounted() {
   
  }
};
</script>

<style scoped>
.modal-container >>> .el-table,
.modal-container >>> .el-table tr,
.modal-container >>> .el-table td,
.modal-container >>> .el-table th {
  background-color: transparent !important;
  border: none;
  text-align: center;
}
.modal-container >>> .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: rgba(29, 51, 110, 0.2) !important;
}
.modal-container >>> .el-table::before {
  background-color: transparent !important;
}
.modal-container >>> .el-table th {
  font-size: 0.15rem;
  color: #00a2ff;
}
.modal-container >>> .el-table .el-table__row td {
  padding: 0.1rem 0;
  font-size: 0.14rem;
  color: #b8c8e9;
}
.modal-container {
  box-sizing: border-box;
  padding: 0.3rem 1.18rem;
}
.modal-title {
  text-align: center;
  color: #fff;
  font-size: 0.28rem;
  font-family: Microsoft YaHei;
}
.modal-date {
  margin-top: 0.3rem;
}
</style>
