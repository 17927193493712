<template>
  <div class="dangerousModal">
    <modal :modelSize="'max'" :modelShow="modelShow" @handelClose="close"  @open="open">
      <template slot="body">
        <div class="modal-container">
          <cvue-table
              :option="tableOption"
              :data="tableData"
              ref="tbl1"
              :isShowAdd="false"
              :page="page"
              :tablePage="page.currentPage"
              :pageSize="page.pageSize"
              :loading="tableLoading"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              :isShowPage="page.total > 0 && tableData.length > 0"
            >
              <!-- 操作按钮 -->
              <el-table-column
                slot="actionMenu"
                label="操作"
                align="center"
                header-align="center"
              >
                <template slot-scope="scope">
                  <div class="operation">
                    <el-button
                      type="text"
                      @click="handLook(scope.row)"
                      style="font-size: 0.16rem"
                    >
                      查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </cvue-table>
        </div>
      </template>
    </modal>


    <dangerousMap :modelShow="mapVisible" @close="mapVisible = false" :student="student" :school="school"></dangerousMap>
  </div>
</template>

<script>
import Modal from "@/components/modal/modal"
import cvueTable from "@/components/cvue-table";
import { getStudentInOutDangerRecord } from "@/api/dangerousApi";
import dangerousMap from './dangerousMap.vue'
export default {
  name: "dangerousInfoModal",
  components: {
    Modal,
    cvueTable,
    dangerousMap
  },
  props:{
    id:{
      type:String,
      value:''
    },
    modelShow: Boolean,
    school: {
      type: Object,
      default: function(){
        return null
      }
    },
    date: String
  },
  data() {
    return {
   
     tableLoading: false,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 8, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50],
      },
      tableOption: {
        stripe: true,
        width: "100%",
        border: false,
        index: false,
        cloumn: [   
          { prop: "creationTime", label: "时间", align: "center" },
          { prop: "studentName", label: "姓名", align: "center" },
          { prop: "schoolName", label: "学校", align: "center" },
          { prop: "gradeName", label: "年级", align: "center" },
          { prop: "className", label: "班级", align: "center" },
          { prop: "schoolDangerRangeConfigName", label: "危险区域名称", align: "center" },
        ],
// "studentId": "string",
//       "studentName": "string",
//       "studentDeviceId": "string",
//       "deviceNo": "string",
//       "schoolDangerRangeConfigId": "string",
//       "schoolDangerRangeConfigName": "string",
//       "classId": "string",
//       "className": "string",
//       "gradeId": "string",
//       "gradeName": "string",
//       "schoolId": "string",
//       "schoolName": "string",
//       "isIntoDanger": true,
//       "isDeleted": true,
//       "deleterId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//       "deletionTime": "2021-12-13T08:55:34.137Z",
//       "lastModificationTime": "2021-12-13T08:55:34.137Z",
//       "lastModifierId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//       "creationTime": "2021-12-13T08:55:34.137Z",
//       "creatorId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//       "id": "string"
      
      },
      tableData: [
        
      ],


      mapVisible: false,
      student: null
    };
  },
  methods: {
    open() {
      this.getList(1)
    },

    close() {
      this.$emit('close')
    },

    handLook(row){
      this.student = row
      this.mapVisible = true
    },

     // 改变pagesize
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList(1)
    },
    // 改变页码
    handleCurrentChange(val) {
   
      this.getList(val)
    },

    async getList(pageNum) {
      this.page.currentPage = pageNum;
      const params = {
        schoolIds : this.school.schoolId,
        startTime: this.formatDate() + ' 00:00:00',
        endTime: this.formatDate() + ' 23:59:59',
        pageNum: this.page.currentPage,
        pageSize: this.page.pageSize
      }
      const res = await getStudentInOutDangerRecord(params);

      this.page.total = res.totalCount
       
      this.tableData = res.items
     
    },
    formatDate(){
        var now = new Date(this.date)
        var year = now.getFullYear()
        var month = now.getMonth() + 1
        var d = now.getDate()
        month = month > 9 ? month : '0' + month
        d = d > 9 ? d : '0' + d
        return `${year}-${month}-${d}`
    },
  
  },
  mounted() {
   
  }
};
</script>

<style scoped>
.modal-container >>> .el-table,
.modal-container >>> .el-table tr,
.modal-container >>> .el-table td,
.modal-container >>> .el-table th {
  background-color: transparent !important;
  border: none;
  text-align: center;
}
.modal-container >>> .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: rgba(29, 51, 110, 0.2) !important;
}
.modal-container >>> .el-table::before {
  background-color: transparent !important;
}
.modal-container >>> .el-table th {
  font-size: 0.15rem;
  color: #00a2ff;
}
.modal-container >>> .el-table .el-table__row td {
  padding: 0.1rem 0;
  font-size: 0.14rem;
  color: #b8c8e9;
}
.modal-container {
  box-sizing: border-box;
  padding: 0.3rem 1.18rem;
}
.modal-title {
  text-align: center;
  color: #fff;
  font-size: 0.28rem;
  font-family: Microsoft YaHei;
}
.modal-date {
  margin-top: 0.3rem;
}
</style>
