<template>
    <div class="map-con">
        <Bmap ref="centerMap"></Bmap>
    </div>
</template>
<script>
import Bmap from '@/components/Bmap'
export default {
    components: {
        Bmap
    },
    mounted () {
        this.$store.state.county.centerMap =  this.$refs.centerMap
    }
}
</script>
<style scoped>
.map-con{
    width: 7.82rem;
    height: 4.95rem;
    border: 0.03rem solid #147ECF;
    border-radius: 0.08rem;
    box-sizing: border-box;
}
</style>
