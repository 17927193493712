<template>
    <div @click="showDetail">
        <div class="box">
            <p class="partName">
                云上党建
            </p>
            <div class="innerBox">   
                <p class="firstRow">
                    <span>
                        日签到人数：
                        <span class="numColor">
                             {{partyDetail.signUpNum}}
                        </span>
                    </span>
                    <span>
                        日人均学习时长：
                        <span class="numColor">
                             {{partyDetail.avgStudyTime}}
                        </span>
                    </span>
                </p>
                <p class="secRow">
                    <span>
                        日完成练习人数：
                        <span class="numColor">
                             {{partyDetail.completeNum}}
                        </span>
                    </span>
                    <span>
                        题库数：
                        <span class="numColor">
                             {{partyDetail.itemBankNum}}
                        </span>
                    </span>
                    <span>
                        文章数：
                        <span class="numColor">
                             {{partyDetail.articleNum}}
                        </span>
                    </span>
                </p>
                <div class="lineBox">
                    <echartLine :data="latestWeekData" title="最近一周学习人数趋势："></echartLine>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import echartLine from './echarts/line'
import {refreshTime} from '@/config/config'
export default {
    components: {
        echartLine
    },
    data () {
        return {
            partyDetail: {
                articleNum: 0,
                avgStudyTime: 0,
                completeNum: 0,
                itemBankNum: 0,
                latestWeekData: [],
                signUpNum: 0
            },
            latestWeekData: [],
            showEcharts: false
        }
    },
    computed: {
        countyUserInfo () {
            return this.$store.state.user.countyUserInfo
        }
    },
  
    methods: {
        showDetail () {
            this.$store.state.county.partyModalShow = true
        },
        getData () {
            this.$store.dispatch('cloudParty/GetData', {eduId: this.countyUserInfo.organizationIdStr}).then(
                res => {
                    if (res) {
                        this.partyDetail = res
                        this.latestWeekData = res.latestWeekData
                        var arr = []
                        this.latestWeekData.forEach(item => {
                            var obj = {name: item.day, value: item.learningNum}
                            arr.push(obj)
                        })
                        this.latestWeekData = arr
                    } else {
                        this.latestWeekData = []
                    }
                }
            )
        },
        refresh () {
            setTimeout(() => {
                this.getData()
                this.refresh()
            }, refreshTime)
        }
    },
    
    mounted () {
        this.getData()
        this.refresh()
    },
}
</script>
<style scoped>
    *{
        color: #fff;
        font-size: 0.14rem;
    }
    .box{
        padding: 0 0.44rem 0 0.38rem;
        width: 5.04rem;
        height: 2.44rem;
        background-image: url('./img/smallBk.png');
        background-size: 100%;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
    }
    .partName{
        text-align: center;
        font-weight: bold;
        font-size: 0.16rem;
    }
    .innerBox{
        padding: 0.3rem 0;
    }
    .innerBox >p{
        line-height: 0.28rem;
    }
    .firstRow >span{
        display: inline-block;
        width: 40%;
        text-align: left;
    }
    .firstRow >span:last-child{
        display: inline-block;
        width: 55%;
        text-align: left;
    }
    .secRow >span:first-child{
        display: inline-block;
        width: 40%;
        text-align: left;
    }
    .secRow >span{
        display: inline-block;
        width: 28%;
        text-align: left;
    }
    .lineBox{
        height: 1.1rem;
        width: 100%;
    }
    .numColor{
        color: #00F9FF;
        font-weight: bold;
    }
</style>