function Marker ({map, position, title,id, click}) {
    this.isDead = false
    this.map = map
    this.position = position
    this.title = title
    this.click = click
    this.id = id
    this.animalType ='normal' // normal alarm
    this.time = 100
    this.now = 0
    

    this.createIconList()
    this.createMarker()
    this.createLabelMarker()
    // this.addLabel({
    //     position,
    //     text: title
    // })
    this.play()
}

Marker.prototype.createIconList = function () {
    this.normalImgList = [
        require('@/assets/img/marker/d01.png'),
        require('@/assets/img/marker/d02.png'),
        require('@/assets/img/marker/d03.png'),
        require('@/assets/img/marker/d04.png'),
        require('@/assets/img/marker/d05.png'),
        require('@/assets/img/marker/d06.png'),
        require('@/assets/img/marker/d07.png'),
        require('@/assets/img/marker/d08.png'),
        require('@/assets/img/marker/d09.png'),
        require('@/assets/img/marker/d10.png'),
        require('@/assets/img/marker/d11.png'),
        require('@/assets/img/marker/d12.png'),
        require('@/assets/img/marker/d13.png'),
        require('@/assets/img/marker/d14.png'),
        require('@/assets/img/marker/d15.png'),
    ]
    this.normalIndex = 0
    this.normal = []
    for (let item of this.normalImgList) {
        let icon = new Image()
        icon.src = item
        this.normal.push(icon)
    }

    this.alarmImgList = [
        require('@/assets/img/marker/f01.png'),
        require('@/assets/img/marker/f02.png'),
        require('@/assets/img/marker/f03.png'),
        require('@/assets/img/marker/f04.png'),
        require('@/assets/img/marker/f05.png'),
        require('@/assets/img/marker/f06.png'),
        require('@/assets/img/marker/f07.png'),
        require('@/assets/img/marker/f08.png'),
        require('@/assets/img/marker/f09.png'),
        require('@/assets/img/marker/f10.png'),
        require('@/assets/img/marker/f11.png'),
        require('@/assets/img/marker/f12.png'),
        require('@/assets/img/marker/f13.png'),
        require('@/assets/img/marker/f14.png'),
        require('@/assets/img/marker/f15.png'),
    ]
    this.alarmIndex = 0
    this.alarm = []
    for (let item of this.alarmImgList) {
        let icon = new Image()
        icon.src = item
        this.alarm.push(icon)
    }
}


Marker.prototype.createMarker = function () {
    this.marker = new AMap.Marker( {   
        position: this.position,
        // icon: this.normal[this.normalIndex],
        content: this.normalImgList[0],
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0,22),
        title: this.title
    })
    this.marker.on('click', () => {
        if (this.click) {
            this.click()
        }
       
    })
    this.map.add(this.marker);

    // var a = new AMap.Marker( {   
    //     position: this.position,
    //     anchor: 'bottom-center',
    //     offset: new AMap.Pixel(0,0)
    // })
    // this.map.add(a);
}
Marker.prototype.createLabelMarker = function () {
    
    let content = document.createElement('div')
    content.innerHTML = this.title
    content.style.position = 'relative'
    content.style.padding = '0.06rem 0.16rem'
    content.style.fontSize = '0.14rem'
    content.style.color = '#FFC000'
    content.style.backgroundColor = 'rgba(2, 20, 70, 0.95)'
    content.style.border = 'solid 1px #347FEF'
    content.style.textAlign = 'center'
    content.style.whiteSpace = 'nowrap'
    content.style.boxShadow = '0 0 10px 2px rgb(38 71 160) inset '

    let url = require('@/assets/img/county/jiao.png')
    let jiao1 = new Image()
    jiao1.src = url
    let jiao2 = jiao1.cloneNode()
    let jiao3 = jiao1.cloneNode()
    let jiao4 = jiao1.cloneNode()

    jiao1.style.position = 'absolute'
    jiao1.style.left = '-1px'
    jiao1.style.top = '-1px'
    
    jiao2.style.position = 'absolute'
    jiao2.style.right = '-1px'
    jiao2.style.top = '-1px'
    jiao2.style.transform = 'rotateZ(90deg)'
    
    jiao3.style.position = 'absolute'
    jiao3.style.right = '-1px'
    jiao3.style.bottom = '-1px'
    jiao3.style.transform = 'rotateZ(180deg)'
    
    jiao4.style.position = 'absolute'
    jiao4.style.left = '-1px'
    jiao4.style.bottom = '-1px'
    jiao4.style.transform = 'rotateZ(270deg)'

    content.appendChild(jiao1)
    content.appendChild(jiao2)
    content.appendChild(jiao3)
    content.appendChild(jiao4)
    this.text = new AMap.Marker( {   
        position: this.position,
      
        content: content,
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0,-60),
        title: this.title,
        draggable: true
    })
    this.map.add(this.text);
}
Marker.prototype.addLabel = function({text, position}) {
    // 创建纯文本标记
    this.text = new AMap.Text({
        text: text,
        anchor:'center', // 设置文本标记锚点
         offset: new AMap.Pixel(0,-80),
        draggable:true,
        cursor:'pointer',

        style:{
           
            'padding': '0.06rem 0.16rem',
            'font-size': '0.14rem',
            'color': '#FFC000',
            'background-color': 'rgba(2, 20, 70, 0.8)',
         
          
            'border': 'solid 1px #347FEF',
            'text-align': 'center',
            
        },
        position: position
    });

    this.text.setMap(this.map);
}

Marker.prototype.playNormal = function () {
    this.normalIndex ++ 
    this.normalIndex = this.normalIndex % this.normal.length
    // console.log(this.normal[this.normalIndex])
    // this.marker.setIcon(this.normal[this.normalIndex])
    
    this.marker.setContent(this.normal[this.normalIndex])
    
}

Marker.prototype.playAlarm = function () {
   
    this.alarmIndex ++ 
    this.alarmIndex = this.alarmIndex % this.alarm.length
    
    this.marker.setContent(this.alarm[this.alarmIndex])
}

Marker.prototype.play = function () {
    if (this.isDead) {
        return
    }

    AMap.Util.requestAnimFrame(() => {
        this.play()
       
        let now = Date.now()
        // console.log(now - this.now)
        if ((now - this.now) > this.time) {
            this.now = now
            
            if (this.animalType == 'normal') {
                this.playNormal()
            } else if (this.animalType == 'alarm') {
                
                this.playAlarm()
            }
            
        }
    })
}

Marker.prototype.changeAnimal = function(type) {
    this.animalType = type
    this.alarmIndex = 0
    this.normalIndex = 0
}

Marker.prototype.destroy = function () {
    this.isDead = true
    this.map.remove([this.marker, this.text])
}

export default Marker