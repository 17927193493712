<template>
    <div>
        <div class="box">
            <p class="partName">
                辖区基础数据
            </p>
            <div class="innerBox">   
                <div>
                    <p>
                        学校（所）
                    </p>
                    <p class="twoColor">
                        {{info.schoolNum}}
                    </p>
                </div>
                <div>
                    <p>
                        班级（个）
                    </p>
                    <p class="twoColor">
                        {{info.classNum}}
                    </p>
                </div>
                <div>
                    <p>
                        摄像头（个）
                    </p>
                    <p class="oneColor"> 
                        {{info.cameraNum}}
                    </p>
                </div>
                <div class="lastOne">
                    <p>
                        报警点（个）
                    </p>
                    <p class="oneColor">
                        {{info.alarmDeviceNum}}
                    </p>
                </div>
                <div>
                    <p>
                        教职工（人）
                    </p>
                    <p class="twoColor">
                        {{info.teacherNum}}
                    </p>
                </div>
                <div>
                    <p>
                        学生（人）
                    </p>
                    <p class="twoColor">
                        {{info.studentNum}}
                    </p>
                </div>
                <div>
                    <p>
                        寄宿生（人）
                    </p>
                    <p class="oneColor">
                        {{info.boarderNum}}
                    </p>
                </div>
                <div class="lastOne">
                    <p>
                        家长（人）
                    </p>
                    <p class="twoColor">
                        {{info.parentNum}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {getBaseInfoData} from '@/api/county'
import {refreshTime} from '@/config/config'
export default {
    data () {
        return {
            info:{
                larmDeviceNum: 0,
                boarderNum: 0,
                cameraNum: 0,
                cardNum: 0,
                classNum: 0,
                parentNum: 0,
                schoolNum: 0,
                studentNum: 0,
                teacherNum: 0,
                alarmDeviceNum: 0
            }
        }
    },
    computed: {
        countyUserInfo () {
          return this.$store.state.user.countyUserInfo
        }
    },
    methods: {
        getData(){
            const params = {
                eduId:this.countyUserInfo.organizationIdStr
            }
            getBaseInfoData(params).then(res => {

                if(res.status == 200 && res.data != null){
                    this.info = res.data
                }
            })
        },
        refresh () {
            setTimeout(() => {
                this.getData()
                this.refresh()
            }, refreshTime)
        }
    },
    mounted () {
        this.getData()
        this.refresh()
    }
}
</script>
<style scoped>
    *{
        color: #fff;
        font-size: 0.14rem;
    }
    .box{
        width: 7.82rem;
        height: 2.44rem;
        background-image: url('./img/bigBk.png');
        background-size: 100%;
        box-sizing: border-box;
        overflow: hidden;
    }
    .partName{
        text-align: center;
        font-weight: bold;
        font-size: 0.16rem;
    }
    .innerBox{
        padding: 0.25rem 0;
        text-align: center;
        display: inline-block;
    }
    .innerBox> div{
        display: inline-block;
        width: 24%;
        box-sizing: border-box;
        /* height: 0.44rem; */
        margin: 0.2rem 0;
        padding:0 0.25rem;
        border-right: 0.01rem solid #203668;
        font-weight: Bold;
    }
    .innerBox> div>p{
        line-height: 0.24rem;
    }
    .innerBox> .lastOne{
        border-width: 0;
    }
    .oneColor{
        color: #FFA800;
        font-size: 0.2rem;
    }
    .twoColor{
        color: #00F9FF;
        font-size: 0.2rem;
    }
</style>