<template>
    <div class="alarm-con">
        <img @click="sendMessage" class="alarm-img" src="@/assets/img/county/alarm.png" alt="">
         
                               
                           
            <div class="alarm-list">
                <div class="alarm-title">报警数据：</div>
                <quee ref="quee" class="marqu" >
                    
                 </quee>
            </div>
        
    </div>
</template>
<script>
import {socketUrl} from '@/config/config'
import {sendMessage} from '@/api/msgApi'
import quee from '@/components/quee'
export default {
    components: {
        quee
    },
    computed: {
        userInfo () {
          if (location.hash == "#/city") {
              return this.$store.state.user.userInfo
          }
          if (location.hash == "#/county") {
              return this.$store.state.user.countyUserInfo
          }
        },
        centerMap () {
            return this.$store.state.county.centerMap
        },
        callPolice () {
            return this.$store.state.county.callPolice
        }
     },
    data () {
        return {
            num: 10
        }
    },
    methods: {
        async sendMessage () {
            //  var d = {"eduId":"39f985d2089a474333b9655e72900b4b","messageId":"e9db6d3d3d694b218e2a28eb19149721",
            // "schoolId":"045f049212dd4a70999fc5aa62c8663c","showMap":true,"viewNum":2,
            // "warnMessage":"【在校预警】预警：测试2异常离校人数达到20"}
            // this.$refs.quee.add(d)
        },
        createData () {
            this.num --
            if (this.num == 0) {
                return
            }


            var d = {"eduId":"39f985d2089a474333b9655e72900b4b","messageId":"e9db6d3d3d694b218e2a28eb19149721",
            "schoolId":"045f049212dd4a70999fc5aa62c8663c","showMap":true,"viewNum":2,
            "warnMessage":"【在校预警】预警：测试2异常离校人数达到20"}
            this.$refs.quee.add(d)
            setTimeout(() => {
                this.createData()
            }, 10000)

        },
        init () {
            var ws = new WebSocket(socketUrl + "/district-command-center?eduId=" + this.userInfo.organizationIdStr)
            ws.onopen = () => {
            　　//当WebSocket创建成功时，触发onopen事件
                console.log("open");
            　　ws.send("hello"); //将消息发送到服务端
            }
            ws.onmessage = (e) => {
            　　//当客户端收到服务端发来的消息时，触发onmessage事件，参数e.data包含server传递过来的数据
            　　console.log(e.data);
                var data = JSON.parse(e.data)
                this.$refs.quee.add(data)
                this.centerMap.alarm(data)
                this.callPolice.getData()
            }
            ws.onclose = (e) => {
            　　//当客户端收到服务端发送的关闭连接请求时，触发onclose事件
            　　console.log("close");
                this.init()
                // this.$alert('websocket断开了', '提示', {
				// 	confirmButtonText: '确定',
				// 	callback: action => {
					
				// 	}
				// })
            }
            ws.onerror = function(error){
            //    alert('websocket链接错误')
            　　//如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
            　　console.log(error);
            }
        }
    },
    mounted () {
        this.init()
    }
}
</script>
<style scoped>
.alarm-con{
    padding: 0.13rem 0.56rem;
}
.alarm-img{
    width: 0.32rem; height: 0.32rem; display: inline-block; vertical-align: middle;
}
.alarm-list{
    color: #FE0000; font-size: 0.16rem; display: inline-block; vertical-align: middle;
}
.alarm-title{
    font-weight: bold; display: inline-block; vertical-align: middle;
}
.alarm-text{
    display: inline-block; vertical-align: middle; margin: 0 0.5rem 0 0; font-weight: bold;
}   
.marqu{
        width: 16rem;
    display: inline-block;
    vertical-align: middle;
}

</style>
