import axios from './axios'
import qs from 'qs'
import {eduUrl} from '@/config/config'

export const getOrganization = (id) => {
    return new Promise((resolve,reject) => {
        axios({
            url: eduUrl + '/api/edu/organizations/' + id,
           
            method: 'get'
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject()
        })
    })
}
