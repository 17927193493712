function CanvasLayer ({map, lnglat, id}) {
    this.isDead = false
    this.map = map
    
    this.id = id

    this.canvas = document.createElement('canvas');
    this.canvas.width = this.canvas.height = 200;

    this.context = this.canvas.getContext('2d')
    this.context.fillStyle = 'rgb(255,10,0)';
    this.context.strokeStyle = 'white';
    this.context.globalAlpha = 1;
    this.context.lineWidth = 2;

    this.radious = 0;
   
    var southWest = lnglat.offset(-1000,-1000)
    var northEast = lnglat.offset(1000,1000)

  
    this.layer = new AMap.CanvasLayer({
        canvas: this.canvas,
        bounds: new AMap.Bounds(
            [southWest.lng, southWest.lat],
            [northEast.lng, northEast.lat]
        ),
    });

    this.layer.setMap(map);
    this.draw();
}

CanvasLayer.prototype.draw = function () {
    if ( this.isDead) {
        return
    }
    console.log('draw...')

    this.context.clearRect(0, 0, 200, 200)
    this.context.globalAlpha = (this.context.globalAlpha - 0.01 + 1) % 1;
    this.radious = (this.radious + 1) % 100;

    this.context.beginPath();
    this.context.arc(100, 100, this.radious, 0, 2 * Math.PI);
    this.context.fill();
    this.context.stroke();

    //2D视图时可以省略
    this.layer.reFresh();

    AMap.Util.requestAnimFrame(() => {
        this.draw()
    });
};
CanvasLayer.prototype.remove = function(){
    this.isDead = true
    this.map.remove(this.layer)
}
CanvasLayer.prototype.destroy = function(){
    this.remove()
}

export default CanvasLayer