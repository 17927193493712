<template>
    <div class="indexModal">
       <modal
        :modelShow="modelShow" 
        @open='open'
        @close='close'
        @handelClose="$emit('handelClose')">
            <template slot="body">
                <div class="modal-container">
                    <div  class="modal-top">
                        <h4>报警数据：</h4>
                        <quee ref="quee" class="marqu" ></quee>
                    </div>
                    
                    <div class="modal-content">
                        <div class="column-left">
                            <div @click="toSchool" class="title">
                                {{data.schoolName}}
                                <i class="icon"></i>
                            </div>
                            <div class="info">
                                <ol>
                                    <li>
                                        <span class="info_title">年&nbsp;&nbsp;级&nbsp;&nbsp;数：</span>
                                        <span class="info_text">{{data.gradeNum}}</span>
                                    </li>
                                    <li>
                                        <span class="info_title">班&nbsp;&nbsp;级&nbsp;&nbsp;数：</span>
                                        <span class="info_text">{{data.classNum}}</span>
                                    </li>
                                    <li>
                                        <span class="info_title">学&nbsp;&nbsp;生&nbsp;&nbsp;数：</span>
                                        <span class="info_text">{{data.studentNum}}</span>
                                    </li>
                                    <li>
                                        <span class="info_title">教职工数：</span>
                                        <span class="info_text">{{data.teacherNum}}</span>
                                    </li>
                                    <li>
                                        <span class="info_title">联系方式：</span>
                                        <span class="info_text">{{data.contactWay}}</span>
                                    </li>
                                    <li>
                                        <span class="info_title">地&nbsp;&nbsp;&nbsp;&nbsp;址&nbsp;&nbsp;&nbsp;：</span>
                                        <span class="info_text">{{data.schoolAddress}}</span>
                                    </li>
                                </ol>
                            </div>
                            <div class="chart" v-if="modelShow">
                                <pieChart 
                                    class="chartItem" 
                                    :pieId="'pie1'" 
                                    :color="['#F73853','#08245C']"
                                    :num="studentNum"
                                    title="在校学生比例">
                                </pieChart>
                                <pieChart 
                                    class="chartItem" 
                                    :pieId="'pie2'" 
                                    :color="['#E2BF52','#08245C']"
                                    :num="[data.teacherProportion, data.teacherNum]"
                                    title="在校教师比例">
                                </pieChart>
                            </div>
                        </div>
                        <div class="column-right">
                            <div class="imgBox">
                                <img :src="data.schoolImages"/>
                            </div>
                            <div class="imgBox">
                                <Bmap ref="myMap"></Bmap>
                            </div>
                        </div>
                    </div>
                    <div style="color:#fff;">
                     
                    </div> 
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
import Modal from '@/components/modal/modal';
import myMap from '@/components/modal/map';
import Bmap from '@/components/Bmap'
import pieChart from '@/components/modal/pieChart'
import {getSchoolDetail} from '@/api/schoolApi'
import {getUnReadMsgList, readMsg} from '@/api/msgApi'
import quee from '@/components/quee'
import {busiUrl} from '@/config/config'
export default {
    name:'indexModal',
    components: {
        Modal,
        myMap,
        pieChart,
        Bmap,
        quee
    },
    props:{
        modelShow:Boolean,
        modelSize:String,
        data1:{
            type:Object,
            default:() => {
                return {
                    
                }
            }
        }
    },
    computed: {
        schoolData () {
            return this.$store.state.county.schoolData
        },
        studentNum () {
            return [this.data.studentProportion, this.data.studentNum]
        },
        centerMap () {
            return this.$store.state.county.centerMap
        }
    },
   
    data () {
        return {
            data: {
                schoolImages:require('@/assets/img/modal-img1.jpg'),                  //学校图片
               
            },
             msgList: []
        }
    },
    methods: {
        async open () {
            await this.getSchoolDetail()
            await this.getUnReadMsgList()
            this.handelMap()
        },
        close () {
            this.$refs.myMap.clear()
        },
        //地图位置
        handelMap(){
            
            this.data
            this.data.latitude
            this.data.longitude
            this.$refs.myMap.schoolMarker(this.data)
        },
        async getSchoolDetail () {
            var res = await getSchoolDetail({
                schoolId: this.schoolData.schoolId
            })
            this.data = Object.assign({}, this.data, res.data)
        },
        async getUnReadMsgList() {
            var res = await getUnReadMsgList({
                schoolId: this.schoolData.schoolId
            })
            this.msgList = res.data

            this.readMsg()

           
            this.$refs.quee.addUnread(this.msgList)
            

            this.centerMap.cancelAlarm(this.schoolData)
        },
        async readMsg () {
            if (this.msgList.length == 0) {
                return
            }
            var list = [].concat(this.msgList)
            var messageIds = list.map(obj => obj.messageId)
            messageIds = messageIds.join()
            var res = await readMsg({
                messageIds: messageIds,
                schoolId: this.schoolData.schoolId
            })
        },
        toSchool(){


            var url = `${busiUrl}/cmdCtr/index.html?schoolId=${this.schoolData.schoolId}#/specialLogin`
            window.open(url)
        },
    },
     mounted() {
      
    }
}
</script>
<style lang="scss" scoped>
    .modal-container{
        box-sizing: border-box;
        padding: 0.16rem 0.52rem;
    }
    .modal-top{
        height:0.32rem;
        line-height:0.32rem;
        color:#FE0000;
        display: flex;
        h4{
            height:0.32rem;
            padding-left:0.4rem;
            background: url(../../assets/img/modal-icon1.png) left center no-repeat;
            background-size:auto 100%;
            font-size:0.16rem;
        }
        .quee{
            flex:1;
            font-size:0.14rem; 
            cursor: pointer;
            marquee{
                width:5rem;
                overflow: hidden;
            }
        }
    }
    .modal-content{
        margin-top:0.25rem;
        display: flex;
        .column-left{
            flex:1;
            color:#fff;
            .title{
                font-size: 0.18rem;
                cursor: pointer;
                .icon{
                    display: inline-block;
                    width:0.2rem;
                    height:0.2rem;
                    background: url(../../assets/img/modal-icon2.png) left center no-repeat;
                    background-size:cover;
                    vertical-align: middle;
                    margin-left: 0.18rem;
                }
            }
            .info{
                margin-top:0.26rem;
                font-size:0.16rem;
                ol{
                    li{
                        display: flex;
                        line-height:0.3rem;
                        span{
                            display: inline-block;
                        }
                        span.info_title{
                            width:0.8rem;
                        }
                        span.info_text{
                            flex:1;
                            margin-left:0.12rem;
                        }
                    }
                }
            }
            .chart{
                display: flex;
                flex-direction:row;
                margin-top:0.32rem;
                .chartItem:last-child{
                    width:65%;
                }
            }
        }
        .column-right{
            width: 2.96rem;
            padding-left:0.15rem;
            .imgBox{
                height:1.84rem;
                overflow: hidden;
                border-radius: 0.05rem;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-bottom:0.3rem;
                img{
                    width:100%;
                    height:auto;
                    transition: all 2s;
                }
                img:hover{
                    transform:scale(1.2);
                }
            }
        }
    }
.marqu{
        width: 5rem;
    display: inline-block;
    vertical-align: middle;
}
</style>