<template>
  <div class="pieChart">
    <view-chart 
        :chartId="pieId" 
        :option="options" 
        :width="width" 
        :height="height">
    </view-chart>
    <h4>{{title}}</h4>
  </div>
</template>
<script>
import viewChart from '@/components/chart/view-chart';
export default {
    components: {
        viewChart
    },
    props:{
        width: {
            type: String,
            default: '0.9rem'
        },
        height: {
            type: String,
            default: '0.9rem'
        },
        pieId:{
            type: String,
            required: true
        },
        color:{
            type:Array,
            default: ''
        },
        title:{
            type: String,
            default: ''
        },
        num:{
            type:Array,
            default: ''
        }
    },
    data(){
        return {
            option:{
                tooltip: {
                    show: false,
                    trigger: 'item',
                    formatter: "{d}%"
                },
                color:[],
                graphic:[
                    {
                        type:'text',
                        left:'center',
                        top:'38%',
                        style:{
                            text:'95%',
                            fill:'#00F9FF',
                            font: '0.18rem Microsoft YaHei'
                        }
                    },
                    {
                        type:'text',
                        left:'center',
                        top:'60%',
                        z:10,
                        style:{
                            text:'3231',
                            fill:'#fff',
                            font: '0.12rem Microsoft YaHei'
                        }
                    }
                ],
                series: [
                    {
                        name:'',
                        type: 'pie',
                        center: ['50%', '50%'], 
                        radius: ['90%', '80%'],
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        animationDuration: 3500,
                        animationEasing: "cubicInOut",
                        data:[
                            {
                                value: '50',
                                name: '',
                                label: {
                                    normal: {
                                        show: false
                                    }
                                }
                            },
                            {
                                value: '50',
                                name: '',
                                label: {
                                    normal: {
                                        show: false
                                    }
                                }
                            }
                        ]
                    }
                ]
            }  
        }
    },
    computed:{
        options(){
            var option = JSON.parse(JSON.stringify( this.option));
            option.color = this.color;
            if(this.num.length > 0){
                option.graphic[0].style.text = this.num[0]+'%';
                option.graphic[1].style.text = this.num[1];
                option.series[0].data[0].value = this.num[0];
                option.series[0].data[1].value = 100 - parseFloat(this.num[0]);
            }
            return option;
        }
    }
};
</script>
<style lang="scss"  scoped>
.pieChart{
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction:column;
    h4{
        font-size:0.12rem;
        margin-top: 0.04rem;
    }
}
</style>