<template>
    <div>
        <view-chart 
            :chartId="random" 
            :option="options" 
            :width="w" 
            :height="h">
        </view-chart>
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
import viewChart from '@/components/chart/view-chart';
export default {
    name:'alarmChart',
    components: {
        viewChart
    },
    props:{
        data:Number
    },
    data(){
        return {
            random:String(Math.random()),
            w:'2rem',
            h:'2rem',
            option:{
                series: [
                    {
                        type: 'gauge',
                        splitNumber:'1',
                        max:2592000000,
                        detail: {
                            formatter:'{value}%',
                            show:false
                        },
                        axisLine: { 
                            lineStyle: { 
                                width: 10,
                                color: [
                                    [1, new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                        offset: 0,
                                        color: '#3BB9E2'
                                    }, {
                                        offset: 1,
                                        color: '#741ED4'
                                    }])]
                                ],
                            }
                        },
                        axisLabel: {            // 坐标轴小标记
                            show:false
                        },
                        axisTick: {            // 仪表盘的线条
                            show:false
                        },
                        splitLine:{            //刻度尺
                            show:false  
                        },
                        pointer:{               //指针长短
                            length:60
                        },
                        itemStyle:{             //指针颜色
                            color:'#597AD6',
                        },
                        animationDuration: 3800,
                        animationEasing: "cubicInOut",
                        data: [{value: 864500000}]
                    }
                ]
            }
        }
    },
    computed:{
        options(){
            var option = this.option;
            option.series[0].data = [];
            option.series[0].data = [{value:this.data}];
            return option
        }
    } 
}
</script>

<style lang="scss" scoped>

</style>