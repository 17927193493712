<template>
    <div class="menu-list" :class="{'is-open': isOpen}" @click.stop>
        <!-- <div class="menu-item">首页</div> -->
        <div class="menu-item">
            健康监测
             <div class="sub-menu">
                
                <div @click="showDefence(1)" class="sub-menu-item">上报数据</div>
                <div @click="showDefence(2)"  class="sub-menu-item">闸机数据</div>
                <div @click="showDefence(3)"  class="sub-menu-item">行程卡数据</div>
            </div>
        </div>
        <div class="menu-item">
            平安校园

             <div class="sub-menu">
                <div @click="showDanger"  class="sub-menu-item">危险区域预警</div>
                <div @click="showSchoolMointor" class="sub-menu-item" v-if="isClient">校园监控</div>
                <div @click="toMonitorCenter" class="sub-menu-item">校园监控</div>
                <div @click="showSchoolData" class="sub-menu-item">实时在校数据</div>
                <div @click="showAlarm"  class="sub-menu-item">校园报警</div>
                <div @click="showTemp"  class="sub-menu-item">体温检测</div>
            </div>
        </div>
        <div class="menu-item">
            教务管理

            <div class="sub-menu">
                <div @click="showParty" class="sub-menu-item">云上党建</div>
                <div @click="showDorm"  class="sub-menu-item">宿舍管理</div>
                <div @click="showAttend"  class="sub-menu-item">课堂考勤</div>
            </div>
        </div>
        <div class="menu-item">教学应用</div>
        <div class="menu-item">
          协同办公
          <div class="sub-menu">
            <div @click="toClassroom" class="sub-menu-item">云上课堂</div>
            <div @click="toVideoConferencing" class="sub-menu-item">视频会议</div>
          </div>
        </div>
        <div class="menu-item">
            系统设置

            <div class="sub-menu">
                <div @click="showIndexSetting" class="sub-menu-item">首页设置</div>
                <div @click="showWarnSetting"  class="sub-menu-item">预警设置</div>
                <!-- <div class="sub-menu-item">监控设置</div> -->

                <div v-for="item in menuList" @click="toMenu(item)" class="sub-menu-item">{{item.label}}</div>
            </div>
        </div>

        <img v-show="!isOpen" @click.stop="open" class="up-arr flash-animate1" src="@/assets/img/county/up-arr.png" alt="" srcset="">
        <img v-show="isOpen" @click.stop="close" class="down-arr" src="@/assets/img/county/down-arr.png" alt="" srcset="">
    </div>
</template>
<script>
import { getMenuList } from '@/api/accountApi'
export default {
  data () {
    return {
      isOpen: false,
      menuList: []
    }
  },
  computed: {
    isClient () {
      return this.$store.getters['user/isClient']
    }
  },
  methods: {
    toMonitorCenter () {
      window.open('/#/monitor-center/')
    },
    toVideoConferencing() {
      window.open('/#/video-conferencing/')
    },
    toClassroom(){
      window.open('/#/classroom/list')
    },

    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    toMenu (item) {
      window.open(item.href)
    },
    showIndexSetting () {
      this.$store.state.county.indexSetShow = true
    },
    showWarnSetting () {
      this.$store.state.county.warningSetShow = true
    },
    showSchoolData () {
      this.$store.state.county.schoolModalShow = true
    },
    showAlarm () {
      this.$store.state.county.alarmModalShow = true
    },
    showTemp () {
      this.$store.state.county.temperatureModalShow = true
    },
    showParty () {
      this.$store.state.county.partyModalShow = true
    },
    showDorm () {
      this.$store.state.county.dormModalShow = true
    },
    showAttend () {
      this.$store.state.county.classModalShow = true
    },
    showSchoolMointor () {
      QCefClient.invokeMethod('toLink', 'videoMonitor')
    },
    showDefence(index){
      this.$store.state.county.defenceModalShow = true
      this.$store.state.county.defenceModalTabIndex = index
    },
    showDanger(){
      
      this.$store.state.county.dangerModelShow = true
    }
  },
  async mounted () {
    var res = await getMenuList({
      systemMark: 'EduCommand'
    })

    this.menuList = res.systemMenu
    console.log(res)
  }
}
</script>
<style lang="scss" scoped>
.menu-list{
    width: 100%; height: 1.16rem; line-height: 1.16rem; background: rgba(0, 9, 30, 0.85);
    position: absolute; z-index:5; bottom: -1.16rem; text-align: center;
    transition: 0.5s;
}
.is-open{
    bottom: 0;
}
.menu-item{
    position: relative;
    display: inline-block; cursor: pointer; margin: 0 0.5rem;
    width: 1.3rem; height: 0.42rem; background: url(../../assets/img/county/menu-bg.png);
    text-align: center; line-height: 0.42rem; color: #fff;
}
.menu-item:hover {
    background: url(../../assets/img/county/menu-bg-a.png);
    color: #00F9FF;
}
.up-arr{
    cursor: pointer;
    width: 0.52rem; height: 0.2rem; position: absolute;top: -0.2rem; left: 50%; transform: translateX(-50%);
}
.down-arr{
     cursor: pointer;
    width: 0.52rem; height: 0.2rem; position: absolute;top: 0; left: 50%; transform: translateX(-50%);
}
.sub-menu{
    display: none; color: #fff; transition: 0.5s;
    position: absolute; left: 0; top: 0; transform: translateY(-100%);
    width: 100%; border: 1px solid #0185D9;
}
.menu-item:hover .sub-menu{
    display: block;
}
.sub-menu-item{
    cursor: pointer;
    width: 1.28rem; height: 0.4rem; line-height: 0.4rem; text-align: center;
    background: #01194A;
}
.sub-menu-item:hover {
    background: #00072F;
}
</style>
