<template>
  <div class="item-p">
    <chart :options="line" :init-options="initOptions" ref="line" autoresize />
  </div>
</template>
<script>
import ECharts from "@/components/echarts/ECharts.vue";
import * as echarts from 'echarts/core';
export default {
  components: {
    chart: ECharts,
  },
  props: {
    data: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    data(newVal, oldVue) {
      this.init();
    },
  },
  data() {
    return {
      line: {
        title: {
          text: this.title,
          x: "center",
          textStyle: {
            color: "#00A2FF",
            fontSize: 14,
          },
          left: -20,
        },
        grid: {
          left: "0%",
          right: "5%",
          bottom: "0%",
          top: "36%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisTick: {
            show: false,
            alignWithLabel: true,
            lineStyle: {
              color: "#fff",
            },
          },
          axisLabel: {
            //字体颜色
            textStyle: {
              //textStyle里面写x轴下的字体的样式
              color: "#B1CEF4",
              fontSize: 10,
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            //字体颜色
            textStyle: {
              //textStyle里面写x轴下的字体的样式
              color: "#B1CEF4",
              fontSize: 10,
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#B1CEF4",
              width: 1,
              opacity: 0.2,
            },
          },
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            itemStyle: {
              color: "rgba(0, 249, 255, 1)"
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(0, 162, 255, 0.8)"
                },
                {
                  offset: 1,
                  color: "rgba(0, 162, 255, 0.1)"
                }
              ])
            }
          }
        ]
      },
      initOptions: {
        renderer: "canvas",
      },
    };
  },
  methods: {
    init() {
      var list = [];
      var xAxisData = [];
      for (var item of this.data) {
        var value = item.value;
        var name = item.name;
        list.push(value);
        xAxisData.push(name);
      }
      this.line.series[0].data = list;
      this.line.xAxis.data = xAxisData;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.item-p {
  width: 100%;
  height: 100%;
}
</style>