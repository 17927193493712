<template>
     <div>
        <div class="map-p">
            <div id="container" style="height:100%;height:100%;"></div>
        </div> 
     </div>
</template>
<script>
export default {
    components: {
        
    },
    data () {
        return {
            map:null,
            marker:null,
            infoWindow:null,
            info:{
                locationName:'',
                lng: 116.481181,
                lat: 39.989792
            }
        }
    },
    methods: {
        init () {
            this.maps();
            this.infoWindows();
            this.addMarkers();
            //this.infoWindow.open(this.map,this.marker.getPosition());
        },
        maps () {
            var map = new AMap.Map("container", {
                resizeEnable:true,
                center: [this.info.lng, this.info.lat],
                zoom: 13,
                viewMode: '3D'
            });
            this.map = map
        },
        //标记
        addMarkers () {
            this.map.clearMap();
            var marker = new AMap.Marker({
                map: this.map,
                position: [this.info.lng, this.info.lat]
            });
            this.marker = marker;

            //鼠标点击marker弹出自定义的信息窗体
            var _this = this;
            AMap.event.addListener(this.marker, 'click', function () {
                _this.infoWindow.open(_this.map,_this.marker.getPosition());
            });
        },
        //信息窗体
        infoWindows () {
            let contentStr = `<div class="locationName">${this.info.locationName}</div>`;
            var infoWindow = new AMap.InfoWindow({
                content:contentStr,
                offset: new AMap.Pixel(0, -45)
            });
            this.infoWindow = infoWindow;
        }
    }
}
</script>
<style lang="scss" scoped>
.map-p{
    position: relative; 
    height:1.84rem;
    width:2.96rem;
}
.map-p >>> .amap-info-content{
    padding:0;
}
.map-p >>> .amap-info-close{
    right: 10px !important;
    top: 12px;
    color: #fff;
}
.map-p >>> .locationName{
    width: auto;
    max-width: 260px;
    position: relative;
    background: #3c94f9;
    color: #fff;
    padding: 10px 30px 10px 10px;
}
.map-p >>> .bottom-center .amap-info-sharp{
    border-top: 8px solid #3c94f9;
}
</style>
