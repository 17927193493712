<template>
    <div @click="showDetail">
        <div class="box">
            <p class="partName">
                体温监测
            </p>
            <div class="innerBox">   
                <div class="left">
                    <p class="rowName">
                        体温监测人次：
                        <span class="numColor">
                            {{info.totalTakeNum}}
                        </span>
                    </p>
                    <p class="rowName">
                        体温异常人数：
                        <span class="numColor">
                            {{info.totalUnusualNum}}
                        </span>
                    </p>
                    <div class="rowName">
                        <p>
                            体温异常最多学校：
                        </p> 
                        <span class="numColor">
                            {{info.unusualMostSchoolName}}
                        </span>
                    </div>
                </div>
                <div class="right">
                    <div class="anBox">
                        <annular :percentNum="info.studentProportion" :speed="1" :background='background'></annular>
                        <p class="anTitle">
                            学生检测率
                        </p>
                    </div>
                    <div class="anBox anTwo">
                        <annular :percentNum="info.teacherProportion" :speed="1" :background='background'></annular>
                        <p class="anTitle">
                            教职工检测率
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import pie from './echarts/pie'
import annular from '@/components/annular'
import {getTemperatureData} from '@/api/county'
import {refreshTime} from '@/config/config'
export default {
    components: {
        pie, annular
    },
    data () {
        return {
            info:{
                studentProportion: 0,
                teacherProportion: 0,
                totalStudentNum: 0,
                totalTakeNum: 0,
                totalTeacherNum: 0,
                totalUnusualNum: 0,
                unusualMostSchoolName: ""
            },
            background: 'linear-gradient(to right, #0295EA , #8121EC)'
        }
    },
    computed: {
        countyUserInfo () {
          return this.$store.state.user.countyUserInfo
        }
    },
    methods: {
        showDetail () {
            this.$store.state.county.temperatureModalShow = true
        },
        getData(){
            const params = {
                eduId:this.countyUserInfo.organizationIdStr
            }
            getTemperatureData(params).then(res => {
                if(res.status == 200 && res.data != null){
                    this.info = res.data;
                }
            })
        },
        refresh () {
            setTimeout(() => {
                this.getData()
                this.refresh()
            }, refreshTime)
        }
    },
    mounted () {
        this.getData()
        this.refresh()
    }
}
</script>
<style scoped>
    *{
        color: #fff;
        font-size: 0.14rem;
    }
    .box{
        padding: 0 0.44rem 0 0.38rem;
        width: 5.04rem;
        height: 2.44rem;
        background-image: url('./img/smallBk.png');
        background-size: 100%;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
    }
    .partName{
        text-align: center;
        font-weight: bold;
        font-size: 0.16rem;
    }
    .innerBox{
        padding: 0.54rem 0;
    }
    .left, .right{
        display: inline-block;
    }
    .left{
        vertical-align: top;
        margin-top: 0;
    }
    .right{
        float: right;
    }
    .rowName{
        line-height: 30px;
    }
    .anBox{
        display: inline-block;
        width: 0.88rem;
        height: 0.88rem;
    }
    .anTitle{
        color: #00A2FF;
        margin-top: 0.16rem;
        text-align: center;
    }
    .anTwo{
        margin-left: 0.4rem;
    }
    .numColor{
        color: #00F9FF;
        font-weight: bold;
    }
</style>