<template>
    <div class="">
       <modal
        :modelSize="'max'"
        :modelShow="modelShow" 
        @open="open"
        @handelClose="$emit('handelClose')">
            <template slot="body">
                <div class="modal-container indexSetModal">
                    <div>
                         <div class="bigLabel">
                             地图报警条件：
                         </div>
                        <el-form style="float: left; width: 80%" inline label-width="2.3rem" label-position="left">
                            <template v-for="(item, index) in dataList">
                                <el-form-item v-if="item.valueType == 1" class="item" :label="item.settingsName" :key="index">
                                    <el-switch
                                        :active-value="1" :inactive-value="0"
                                        v-model="item.settingsValue.onOff"
                                        >
                                    </el-switch>
                                </el-form-item>
                            </template>
                            
                            <!-- <el-form-item class="item" label="单校报警数量达阈值">
                                <el-switch
                                    v-model="value"
                                    >
                                </el-switch>
                            </el-form-item>
                            <el-form-item class="item" label="单校异常离校数量达阈值">
                                <el-switch
                                    v-model="value"
                                    >
                                </el-switch>
                            </el-form-item>
                            <el-form-item class="item" label="单校体温异常数量达阈值">
                                <el-switch
                                    v-model="value"
                                    >
                                </el-switch>
                            </el-form-item>
                            <el-form-item class="item" label="单校报警数量达阈值">
                                <el-switch
                                    v-model="value"
                                    >
                                </el-switch>
                            </el-form-item>
                            <el-form-item class="item" label="单校宿舍考勤异常数量达阈值">
                                <el-switch
                                    v-model="value"
                                    >
                                </el-switch>
                            </el-form-item> -->
                        </el-form>
                    </div>
                    <div>
                         <div class="bigLabel">
                             首页模块配置：
                         </div>
                        <el-form style="float: left; width: 80%" inline label-width="0.2rem">
                            
                            <el-form-item v-for="(item2, index) in setting.settingsValue" class="item" :key="index">
                                <select v-model="item2.templateId" @change="changeTempalte" class="selectStyle">
                                    <option v-for="item3 in templateList" :key="item3.templateId" 
                                    :label="item3.templateName" :value="item3.templateId"
                                    >

                                    </option>
                                </select>
                                <div class="imgBox">
                                
                                    <img :src="getImg(imgList[index])" alt="">
                                </div>
                            </el-form-item>
                             
                            <!-- <el-form-item>
                                <select v-model="values" class="selectStyle">
                                    <option label="校园报警" value="校园报警">

                                    </option>
                                </select>
                                <div class="imgBox">
                                    <img src="@/assets/img/modal/bao.png" alt="">
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <select v-model="values" class="selectStyle">
                                    <option label="校园报警" value="校园报警">

                                    </option>
                                </select>
                                <div class="imgBox">
                                    <img src="@/assets/img/modal/wen.png" alt="">
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <select v-model="values" class="selectStyle">
                                    <option label="校园报警" value="校园报警">

                                    </option>
                                </select>
                                <div class="imgBox">
                                    <img src="@/assets/img/modal/yun.png" alt="">
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <select v-model="values" class="selectStyle">
                                    <option label="校园报警" value="校园报警">

                                    </option>
                                </select>
                                <div class="imgBox">
                                    <img src="@/assets/img/modal/su.png" alt="">
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <select v-model="values" class="selectStyle">
                                    <option label="校园报警" value="校园报警">

                                    </option>
                                </select>
                                <div class="imgBox">
                                    <img src="@/assets/img/modal/gong.png" alt="">
                                </div>
                            </el-form-item> -->
                        </el-form>
                    </div>
                </div>
                <div class="footer">
                    <span @click="handEdit"><i class="el-icon-edit"></i>修改</span>
                    <span @click="$emit('handelClose')">取消</span>
                </div>
            </template>
        </modal>
    </div>    
</template>

<script>
import Modal from '@/components/modal/modal';
import dormChart from '@/components/modal/dormChart';
export default {
    name:"indexSetModal",
    components: {
        Modal,
        dormChart
    },
    props:{
        modelShow:Boolean
    },
    computed: {
        countyUserInfo () {
            return this.$store.state.user.countyUserInfo
        }
    },
    filters: {
        showOption(){

        }
    },
    data () {
        return {
            value: false,
            values: '校园报警',
            imgList: ['shu.png', 'bao.png', 'wen.png', 'yun.png', 'su.png', 'gong.png', 'shu.png', 'bao.png', 'wen.png', 'yun.png', 'su.png', 'gong.png'],
            dataList: [],
            templateList: [],
            setting: {}
        }
    },
    
    methods: {
        async open () {
            Object.assign(this.$data, this.$options.data())
            
            await this.getTemplate()
            await this.getBaseSet()
        },
        // 获取图片
        getImg (img) {
            var a = require('./img/'+img)
            return a
        },
        // 获取设置列表
        async getBaseSet () {
            var params = {
                eduId: this.countyUserInfo.organizationIdStr,
                settingsType: 1
            }
            this.dataList = await this.$store.dispatch('indexSet/GetBaseSet', params)
            for(var item of this.dataList){
                if(item.valueType == 4){
                    this.setting = item
                }
            }
            
            if(!this.setting.settingsValue){
                
                this.setting.settingsValue = {}
                for(var i = 0; i < 6; i++){
                    var temp = this.templateList[i]
                    this.setting.settingsValue[i + 1] = {
                        templateName: temp.templateName,
                        templateCode: temp.templateCode,
                        templateId: temp.templateId
                    }
                }

            }
            
           
        },
        // 获取模块列表
        async getTemplate () {
            this.templateList = await this.$store.dispatch('indexSet/GetTemplate')
           
            
        },
        // 更新设置 
        handEdit () {
            this.$store.dispatch('indexSet/UpdateHomepageSettings', this.dataList).then(
                res => {
                    this.$message.success('修改成功')

                    this.$emit('setingChange')
                }
            )
        },
        changeTempalte(e){
            
            let id = e.target.value
            let template = ''
            for(var temp of this.templateList){
                if(id == temp.templateId){
                    template = temp
                }
            }

            // <template v-for="item in dataList">
            //                     <template v-if="item.valueType == 4">
            //                         <el-form-item v-for="(item2, index) in item.settingsValue"
            
          
                    for(var key in this.setting.settingsValue){
                        var se = this.setting.settingsValue[key]
                        
                        if(se.templateId == template.templateId){
                            
                            this.setting.settingsValue[key] = JSON.parse(JSON.stringify(template)) 
                        }
                    }
                
            
        }

    }
}
</script>

<style lang="scss" scoped>
.modal-container{
    box-sizing: border-box;
    padding:0.3rem 1rem 0.3rem 2.5rem;
    >div{
        overflow: hidden;
    }
    .modal-title{
        text-align: center;
        color:#fff;
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
    }
    .modal-list{
        margin-top:0.5rem;
        h4{
            font-size:0.16rem;
            font-weight: 700;
            color:#00A2FF;
        }
    }
}
</style>
<style lang='scss'>
.indexSetModal{
    *{
        font-size: 0.16rem;
    }
    .modal-list .el-table, 
    .modal-list .el-table tr, 
    .modal-list .el-table td, 
    .modal-list .el-table th {
        background-color: transparent!important;
        border: none;
        text-align: center;
    }
    .modal-list .el-table{
        margin-top:0.35rem;
    }
    .modal-list .el-table::before{
        background-color: transparent!important;
    }
    .modal-list .el-table th{
        font-size:0.16rem;
        color:#fff;
        height: 0.4rem;
    }
    .modal-list .el-table td{
        font-size:0.14rem;
        color:#B8C8E9;
    }
    .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {/*滚动条整体样式*/
        width: 0.1rem;    
        height: 0.1rem;
    }
    .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
        background: #395283;
    }
    .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {/*滚动条里面轨道*/
        background: #152952;
        border-radius:0.1rem;
    }
    .item >.el-form-item__label{
        color: #fff;
    }
    .bigLabel{
        color: #00A2FF;
        width: 14%;
        float: left;
        line-height: 0.42rem;
    }
    .selectStyle{
        width: 2.6rem;
        height: 0.3rem;
        background: #0C508C;
        color: #FFFFFF;
    }
    .selectStyle option{
        background: #fff;
        color: #0C508C;
    }
    .item .el-switch__core{
        background: #0E2750;
        border-width: 0;
    }
    .item .el-switch.is-checked .el-switch__core{
        background: #099D2F;
    }
    .imgBox{
        width: 2.6rem;
        height: 0.74rem;
        background: url('../../assets/img/modal/imgBk.png') no-repeat;
        background-size: 100%;
        padding: 0.17rem 0;
        text-align: center;
        box-sizing: border-box;
        img{
            width: 0.4rem;
            height: 0.4rem;
        }
    }
    
}
.footer{
        text-align: center;
        >span{
            cursor: pointer;
            display: inline-block;
            width: 1.16rem;
            height: 0.38rem;
            line-height: 0.38rem;
            color: #fff;
            background: #136DBB;
        }
        >span:first-child{
            margin-right: 0.34rem;
        }
    }

</style>