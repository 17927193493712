<template>
  <div class="item-p">
    <chart :options="bar" :init-options="initOptions" ref="bar" theme="ovilia-green" autoresize />
  </div>
</template>
<script>
import ECharts from "@/components/echarts/ECharts.vue";

export default {
  components: {
    chart: ECharts
  },
  props: {
    //[{name:"推荐量",color:"#34243",value:123},
    //{name:"阅读量",color:"#34243",value:123},
    //{name:"转发量",color:"#34243",value:123},
    //{name:"收藏量",color:"#34243",value:123}
    //]
    data: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: ""
    }
  },
  watch: {
    data(newVal, oldVue) {
      this.init()
    }
  },
  data() {
    return {
      colorList: ['#38DEFC','#4B9EFB', '#90EB89', '#F7B74B', '#F0702E','#ED342F',  '#5140F3'],
      bar: {
        title: { 
            text: this.title,
            x: 'center',
            textStyle:{
                color: '#00A2FF',
                fontSize: 14
            },
            left: -20
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //         type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        //     }
        // },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "30%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              show: false,
              alignWithLabel: true,
              lineStyle: {
                color: "#fff"
              }
            },
            axisLabel: {
              //字体颜色
              textStyle: {
                //textStyle里面写x轴下的字体的样式
                color: "#B1CEF4",
                fontSize: 10
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#01DFF4"
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            show: true,
            // inverse:ture,
            splitLine: {
              //去除网状线
              show: false
            },
            axisLabel: {
              //用于设置是否显示y轴下的字体 默认为true 
              show: false
            },
            axisLine: {
              //用于设置是否显示y轴那一条线 默认为true
              show: false,
              lineStyle: {
                color: "#ffff",
                width: 2
              }
            },
            splitLine: {
                lineStyle: {
                    type: 'dotted',
                    color: '#B1CEF4',
                    width: 1,
                    opacity: 0.3
                }
            }
          }
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "40%",
            label: {
              show: true,
              position: "top", //数据在上方显示
              color:'#B1CEF4',
              fontSize:10,
              formatter: '{c}'
              // textStyle: {
              //   fontWeight: "bolder",
              //   fontSize: "12",      
              //   color: "fff" 
              // }
            },
            itemStyle: {
              normal: {
                color: params => {
                  return this.colorList[params.dataIndex]
                }
              }
            },
            data: []
          }
        ]
      },

      initOptions: {
        renderer: "canvas"
      }
    };
  },
  methods: {
    init() {
      var list = []
      var xAxisData = []
      var colorList = []
      for (var item of this.data) {
        var value = item.value
        var name = item.name
        list.push(value)
        xAxisData.push(name)
        colorList.push(item.color)
      }
      this.bar.series[0].data = list
      this.colorList = colorList
      this.bar.xAxis[0].data = xAxisData
    }
  },
  mounted() {
    this.init()
  }
};
</script>
<style scoped>
.item-p {
  width: 100%;
  height: 100%;
}
</style>