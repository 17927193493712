<template>
  <div class="con-cah">
    <chart :options="pie" :init-options="initOptions" ref="pie" autoresize />
  </div>
</template>
<script>
import ECharts from '@/components/echarts/ECharts.vue'

export default {
  components: {
    chart: ECharts
  },
  props: {
    // [{name:"推荐量",color:"#34243",value:123},
    // {name:"阅读量",color:"#34242",value:123},
    // {name:"转发量",color:"#34243",value:123},
    // {name:"收藏量",color:"#34243",value:123}
    // ]
    data: {
      type: Array,
      default: []
    },
    colorList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  watch: {
    data (newVal, oldVue) {
      this.init()
    }
  },
  data () {
    return {
      initOptions: {
        renderer: 'canvas'
      },
      pie: {
        color: ['#0691EA', '#ED7F26', '#90EB89', '#F7B74B', '#F0702E'],
        tooltip: { // 鼠标悬停提示
          show: true,
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          // orient: "vertical",
          bottom: 0,
          left: 'center',
          textStyle: {
            color: '#fff'
          },
          icon: 'circle',
          itemWidth: 8, // 设置宽度
          itemHeight: 8, // 设置高度
          data: []
        },
        series: [
          {
            name: this.title,
            type: 'pie',
            radius: ['25%', '60%'],
            center: ['50%', '50%'],
            // roseType: 'angle',// 南丁格尔图
            label: {
              normal: {
                show: false, // 显示数据
                formatter: '{b}  {d}% \n {c}',
                color: '#fff',
                // position: "inner", //隐藏标识线
                textStyle: {
                  fontWeight: 300,
                  fontSize: 12 // 文字的字体大小
                }
              }
            },
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  methods: {
    init () {
      var list = []
      var legendData = []
      var colorList = []
      for (var item of this.data) {
        // var color = item.color
        list.push({
          value: item.value,
          name: item.name
        })
        legendData.push(item.name)
        // colorList.push(color)
      }
      this.pie.series[0].data = list
      this.pie.legend.data = legendData
      this.pie.color = this.colorList
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style  scoped>
.con-cah {
  width: 100%;
  height: 100%;
}
</style>
