<template>
    <div class="main" :id="id">

    </div>
</template>
<script>
import CanvasLayer from '@/util/CanvasLayer'
import Marker from '@/util/Marker'
export default {
    data () {
        return {
            id: 'container_' + Math.random(),
            map: null,
            icon: require('@/assets/img/county/marker.gif'),

            schoolList: [],
            canvasLayerList: [],
            markerList: []
        }
    },
    methods: {
       
        init () {
            return new Promise((resolve, reject) => {
                this.map = new AMap.Map(this.id, {
           
           
                    pitch:60,
                
                    viewMode:'3D',
                
                    // mapStyle: "amap://styles/blue",
                    mapStyle:'amap://styles/4abd51624130603c48031babfdf1c5b2'
                
                })

                this.map.on("complete", function(){
                    resolve()
                })
                this.map.on('click', (e) => {
                    if (location.host == 'local.fjfuyu.net') {
                        var position = new AMap.LngLat( e.lnglat.getLng(), e.lnglat.getLat())
                        let m = new Marker({
                            map: this.map,
                            position,
                            title: '点击',
                            click: () => {
                                if (m.animalType == 'normal') {
                                    m.changeAnimal('alarm')
                                } else if (m.animalType == 'alarm') {
                                    m.changeAnimal('normal')
                                }
                                
                            }
                        })
                    }
                    
                })

                AMap.plugin('AMap.ControlBar',() => {
                    this.map.addControl(new AMap.ControlBar({
                        showZoomBar:false,
                        showControlButton:true,
                        position:{
                        right:'10px',
                        top:'10px'
                        }
                    }))
                })
            })
        },
        
         alarm (data) {
            var schoolId = data.schoolId
            for (let item of this.markerList) {
                if (item.id == schoolId) {
                    item.changeAnimal('alarm')
                }
            }
            // for (var item of this.schoolList) {
            //     if (item.schoolId == schoolId) {
            //         var position = new AMap.LngLat(item.longitude, item.latitude)
                    
            //     }
            // }
        },
        cancelAlarm (data) {
            var schoolId = data.schoolId
            for (let item of this.markerList) {
                if (item.id == schoolId) {
                    item.changeAnimal('normal')
                }
            }
        },
        addCanvasLayer ({position, id}) {
            var a = new CanvasLayer({map: this.map, lnglat: position, id})
            this.canvasLayerList.push(a)
        },
        removeCanvasLayer (id) {
            for (var item of this.canvasLayerList) {
                if (item.id == id) {
                    item.remove()
                }
            }
        },
        
        setHeatData () {
            
        },
        addSchoolMarker (list) {
            this.schoolList = list
            for (let item of list) {
                if (!item.longitude) {
                    continue
                }

                let m = new Marker({
                    map: this.map,
                    position: new AMap.LngLat(item.longitude, item.latitude),
                    title: item.schoolName,
                    id: item.schoolId,
                    click: () => {
                        this.$store.state.county.schoolData = item
                        this.$store.state.county.indexModelShow = true
                    }
                })
                this.markerList.push(m)
            }

            this.map.setFitView()
        },
        schoolMarker (school) {

            var position = new AMap.LngLat(school.longitude, school.latitude)

            var m = new Marker({
                map: this.map,
                position,
                title: school.schoolName
            })
            this.markerList.push(m)

             this.map.setFitView()
             this.map.setZoom(14)

            //  this.addCanvasLayer({position, schoolId: school.schoolId})
           
        },

        clear () {
            for(var item of this.canvasLayerList) {
                item.destroy()
            }
            this.canvasLayerList = []
            for(var item of this.markerList) {
                item.destroy()
            }
            this.markerList = []

            this.map.clearMap()
        },
        searchDist (areaId) {
             AMap.plugin(['AMap.DistrictSearch'], () => {
                 var opts = {
                    subdistrict: 0,   //获取边界不需要返回下级行政区
                    extensions: 'all',  //返回行政区边界坐标组等具体信息
                    level: 'district'  //查询行政级别为 市
                };
                this.district = new AMap.DistrictSearch(opts);
                this.district.setLevel('district')
                this.district.search(areaId, (status, result) => {
                    
                    var center = result.districtList[0].center
                    this.map.setZoomAndCenter(12, center)
                })
             })
              
        },
        test () {
            
            var center = this.map.getCenter()
            var school = {
                schoolName: '路奇测试',
                longitude: center.lng,
                latitude: center.lat
            }
            this.schoolMarker(school)
        }
    },
    async mounted() {
       await this.init()
    }
}
</script>
<style scoped>
.main{
    width: 100%; height: 100%;
}
</style>
<style>
.amap-logo{
    display: none !important;
}
</style>
