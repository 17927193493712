<template>
    <div @click="showDetail">
        <div class="box">
            <p class="partName">
                实时在校数据
            </p>
            <div class="innerBox">   
                <div class="left">
                    <p class="rowName">
                        在校学生：
                        <span class="numColor">
                            {{data.currentStudentNum}}
                        </span>
                    </p>
                    <p class="rowName">
                        在校教师：
                        <span class="numColor">
                            {{data.currentTeacherNum}}
                        </span>
                    </p>
                </div>
                <div class="right">
                    <div class="anBox">
                        <annular :percentNum="data.studentProportion" :speed="1"></annular>
                        <p class="anTitle">
                            学生在线率
                        </p>
                    </div>
                    <div class="anBox anTwo">
                        <annular :percentNum="data.teacherProportion" :speed="1"></annular>
                        <p class="anTitle">
                            教职工在线率
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import pie from './echarts/pie'
import annular from '@/components/annular'
import {getSchoolData} from '@/api/county'
import {refreshTime} from '@/config/config'
export default {
    components: {
        pie, annular
    },
    data () {
        return {
            data:{
                currentStudentNum:0,
                currentTeacherNum:0,
                studentProportion:0,
                teacherProportion:0
            },
            background: 'linear-gradient(to right, #0295EA , #8121EC)',
        }
    },
    computed: {
        countyUserInfo () {
          return this.$store.state.user.countyUserInfo
        }
    },
    methods: {
        showDetail () {
            this.$store.state.county.schoolModalShow = true;
        },
        getData(){
            const params = {
                eduId:this.countyUserInfo.organizationIdStr
            }
            getSchoolData(params).then(res => {
                if(res.status == 200 && res.data != null){
                   this.data = res.data;
                }
            })
        },
        refresh () {
            setTimeout(() => {
                this.getData()
                this.refresh()
            }, refreshTime)
        }
    },
    mounted () {
        this.getData()
        this.refresh()
    }
}
</script>
<style scoped>
    *{
        color: #fff;
        font-size: 0.14rem;
    }
    .box{
        padding: 0 0.6rem 0 0.48rem;
        width: 5.04rem;
        height: 2.44rem;
        background-image: url('./img/smallBk.png');
        background-size: 100%;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
    }
    .partName{
        text-align: center;
        font-weight: bold;
        font-size: 0.16rem;
    }
    .innerBox{
        padding: 0.54rem 0;
    }
    .left, .right{
        display: inline-block;
    }
    .left{
        vertical-align: top;
        margin-top: 0.12rem;
    }
    .right{
        float: right;
    }
    .rowName{
        line-height: 48px;
    }
    .anBox{
        display: inline-block;
        width: 0.88rem;
        height: 0.88rem;
    }
    .anTitle{
        color: #00A2FF;
        margin-top: 0.16rem;
        text-align: center;
    }
    .anTwo{
        margin-left: 0.4rem;
    }
    .numColor{
        color: #00F9FF;
        font-weight: bold;
    }
</style>