<template>
    <div class="temperatureModal">
       <modal
        :modelSize="'max'"
        :modelShow="modelShow"
        @open="handelOpen" 
        @handelClose="$emit('handelClose')">
            <template slot="body">
                <div class="modal-container temperatureModal">
                    <div class="modal-title">体温监测</div>
                    <div class="modal-list">
                        <h4>今日体温异常名单</h4>
                        <ul ref="itemsOne">
                            <li v-for="(item,index) of listOne" :key="index">
                                <div class="name">{{item.schoolName}}</div>
                                <div class="label">
                                    <span>{{item.personName}}</span>
                                    <span>
                                        <span v-if="item.roleId == '1' ? true : false">学生</span>
                                        <span v-else-if="item.roleId == '2' ? true : false">教师</span>
                                        <span v-else>访客</span> 
                                    </span>
                                    <span>{{item.temperature}}℃</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-list" style="margin-top:0.7rem">
                        <h4>今日各校体温监测情况</h4>
                        <el-table 
                            :data="listTwo" 
                            style="width: 100%"
                            height="2.76rem">
                            <el-table-column prop="schoolName" label="学校名称"></el-table-column>
                            <el-table-column prop="totalTakeNum" label="测温人次"></el-table-column>
                            <el-table-column prop="studentTakeNum" label="测温学生数"></el-table-column>
                            <el-table-column prop="teacherTakeNum" label="测温教师数"></el-table-column>
                            <el-table-column prop="visitorTakeNum" label="测温访客数"></el-table-column>
                        </el-table>
                    </div>
                </div>
            </template>
        </modal>
    </div>    
</template>

<script>
import Modal from '@/components/modal/modal';
import {getTemperatureUnusual,getTemperatureDataDetail} from '@/api/county';
export default {
    name:"temperatureModal",
    components: {
        Modal
    },
    props:{
        modelShow:Boolean,
        modelSize:String
    },
    data(){
        return {
            listOne:[],
            listTwo:[]
        }
    },
    computed:{
        countyUserInfo(){
          return this.$store.state.user.countyUserInfo
        }
    },
    watch:{
        modelShow(){
            if(!this.modelShow){
                this.handelRollTop(this.$refs.itemsOne);
            }
        }
    },
    methods: {
        handelTemperatureUnusual(){
            const params = {
                eduId:this.countyUserInfo.organizationIdStr
            }
            getTemperatureUnusual(params).then(res => {
                if(res.status == 200 && res.data != null){
                   this.listOne = res.data;
                }
            })
        },
        handelTemperatureDataDetail(){
            const params = {
                eduId:this.countyUserInfo.organizationIdStr
            }
            getTemperatureDataDetail(params).then(res => {
                if(res.status == 200 && res.data != null){
                   this.listTwo = res.data;
                }
            })
        },
        handelRollTop(dom){
            dom.scrollTop = 0;
        },
        handelOpen(){
            this.handelTemperatureUnusual();
            this.handelTemperatureDataDetail()
        }
    },
}
</script>

<style lang="scss" scoped>

    .modal-container{
        box-sizing: border-box;
        padding:0.3rem 1.75rem;
        .modal-title{
            text-align: center;
            color:#fff;
            font-size: 0.28rem;
            font-family: Microsoft YaHei;
        }
        .modal-list{
            margin-top:0.5rem;
            h4{
                font-size:0.16rem;
                font-weight: 700;
                color:#00A2FF;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                height:1.55rem;
                overflow: hidden;
                overflow-y:auto;
                margin-top:0.35rem;
                li{
                    width: 33.3%;
                    margin-bottom:0.4rem;
                    .name{
                        font-size:0.16rem;
                        color:#fff;
                    }
                    .label{
                        margin-top: 0.15rem;
                        font-size:0.14rem;
                        color:#90A3C8;
                        span{
                            display: inline-block;
                            margin-right:0.28rem;
                        }
                        span:first-child{
                            padding-left:0.25rem;
                            background:url(../../assets/img/modal-icon3.png) no-repeat;
                            background-size:auto 100%;
                        }
                    }
                }
            }
            ul::-webkit-scrollbar {/*滚动条整体样式*/
                width: 0.1rem;    
                height: 0.1rem;
            }
            ul::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
                background: #395283;
            }
            ul::-webkit-scrollbar-track {/*滚动条里面轨道*/
                background: #152952;
                border-radius:0.1rem;
            }
        }
    }

</style>
<style>
.temperatureModal .modal-list .el-table, 
.temperatureModal .modal-list .el-table tr, 
.temperatureModal .modal-list .el-table td, 
.temperatureModal .modal-list .el-table th {
    background-color: transparent!important;
    border: none;
    text-align: center;
}
.temperatureModal .modal-list .el-table{
    margin-top:0.35rem;
}
.temperatureModal .modal-list .el-table::before{
    background-color: transparent!important;
}
.temperatureModal .modal-list .el-table th{
    font-size:0.16rem;
    color:#fff;
}
.temperatureModal .modal-list .el-table td{
    font-size:0.14rem;
    color:#B8C8E9;
    padding:0.18rem 0;
}
.temperatureModal .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {/*滚动条整体样式*/
    width: 0.1rem;    
    height: 0.1rem;
}
.temperatureModal .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    background: #395283;
}
.temperatureModal .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {/*滚动条里面轨道*/
    background: #152952;
    border-radius:0.1rem;
}
</style>
