import axios from './axios'
import qs from 'qs'
import { apiUrl,appUrl } from '@/config/config'


export const getStatToday = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: apiUrl + '/district-command-center/student/intoDangerArea/statToday',
      method: 'get',
      params
    }).then(res => {
      resolve(res.data)
    })
  })
}

export const getStatLatestWeek = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: apiUrl + '/district-command-center/student/intoDangerArea/statLatestWeek',
      method: 'get',
      params
    }).then(res => {
      resolve(res.data)
    })
  })
}


export const getAreaSchoolDanger = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: apiUrl + '/district-command-center/student/intoDangerArea/stat/school',
      method: 'get',
      params
    }).then(res => {
      resolve(res)
    })
  })
}

export const getStudentInOutDangerRecord = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: appUrl + '/school/api/attence/studentInOutDangerRecord',
      method: 'get',
      params
    }).then(res => {
      resolve(res)
    })
  })
}

export const schoolDangerRangeConfig = (params) => {
    return new Promise((resolve, reject) => {
      axios({
        url: appUrl + '/school/api/attence/schoolDangerRangeConfig',
        method: 'get',
        params
      }).then(res => {
        resolve(res)
      })
    })
  } 

