<template>
    <div class="left-con left-arear" :class="{'is-open': isOpen}" @click.stop>
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" default-expand-all>
            <span class="custom-tree-node" slot-scope="{ node, data }">
                <span v-if="node.level == 1">{{ data.areaInfo.areaName }}</span>
                <span v-if="node.level == 2">{{ data.schoolName }}</span>
             </span>
        </el-tree>

        <img v-show="isOpen" @click.stop="close" class="left-arr" src="@/assets/img/county/left-arr.png" alt="" srcset="">
        <img v-show="!isOpen" @click.stop="open" class="right-arr flash-animate1" src="@/assets/img/county/right-arr.png" alt="" srcset="">

        <!-- {{countyUserInfo}} -->
    </div>
</template>
<script>
import {getSchoolList} from '@/api/schoolApi'
import {getOrganization} from '@/api/organizationApi.js'
export default {
     computed: {
        countyUserInfo () {
          return this.$store.state.user.countyUserInfo
        },
        centerMap () {
            return this.$store.state.county.centerMap
        }
     },
     data() {
      return {
        isOpen: false,
        data: [],
        defaultProps: {
          children: 'schools',
          label: 'areaInfo.areaName'
        }
      };
    },
    methods: {
      handleNodeClick(data, node) {
        console.log(data, node);
        if (node.level == 1) {
            
            this.centerMap.searchDist(data.areaInfo.areaId)
            return
        }
        if (data.longitude) {
             this.centerMap.map.setZoomAndCenter(14, [data.longitude, data.latitude])
        } else {
            this.$message.error('学校没有坐标')
        }
       
      },
      close () {
          this.isOpen = false
      },
      open () {
          this.isOpen = true
      },
      async getSchoolList () {
        var res = await getSchoolList(this.countyUserInfo.organizationIdStr)
        this.data = res.data.areas
        
        
      },

      async addSchoolMarker () {
          var schoolList = []
          for (var item of this.data) {
              for (var s of item.schools) {
                
                   schoolList.push(s)
                //   this.centerMap.addMarker( new AMap.LngLat(s.longitude, s.latitude))
              }
          }

          this.centerMap.addSchoolMarker(schoolList)

          
        if (schoolList.length == 0) {
            var res = await getOrganization(this.countyUserInfo.organizationIdStr)
        
            this.centerMap.searchDist(res.area.id)
        }
      }
    },
    async mounted () {
        await this.getSchoolList()
         this.addSchoolMarker()
        
    }
}
</script>
<style scoped>
.left-con{
    width: 2.5rem; height: 100%; background: rgba(0, 9, 30, 0.85);
    position: absolute;z-index: 10; top: 0; left: -2.5rem; box-sizing: border-box;
    transition: 0.5s; padding: 0.6rem 0.2rem 0 0.2rem;
    color: #fff;
}
.is-open{
    left: 0; overflow-y: scroll;
}
.left-arr{
    cursor: pointer; width: 0.2rem; height: 0.52rem; position: absolute; right: 0; top: 50%;
    transform: translateY(-50%);
}


.right-arr{
    cursor: pointer; width: 0.2rem; height: 0.52rem; position: absolute; right: -0.2rem; top: 50%;
    transform: translateY(-50%);
}
</style>
<style lang="scss">
.left-arear{
    .el-tree{
        background: none !important;
        color: #fff !important;
        
    }
    .el-tree-node__label{
        font-size: 0.16rem !important;
    }
    .el-tree-node__content{
       padding-top: 0.05rem; padding-bottom: 0.05rem;
    }
    .el-tree-node__content:hover {
        background:rgba(11, 89, 158, 1);
    }
    .el-tree-node:focus>.el-tree-node__content {
        background: rgba(11, 89, 158, 1);
    }
    .el-tree-node__expand-icon{
        color: rgba(64, 131, 191, 1);
    }
    .el-tree-node__expand-icon.is-leaf{
        color: transparent;
    }
}
</style>
