<template>
    <div class="classModal">
       <modal
        :modelSize="'max'"
        :modelShow="modelShow" 
        @open="open"
        @handelClose="$emit('handelClose')">
            <template slot="body">
                <div class="modal-container">
                    <div class="modal-title">课堂考勤</div>
                    <div class="modal-itemData">
                        <h4>今日课堂考勤明细</h4>
                        <div class="viewChart" v-if="isReload">
                            <view-chart 
                                :chartId="'classChart'" 
                                :option="options" 
                                :width="'100%'" 
                                :height="'6.5rem'">
                            </view-chart>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
    </div>    
</template>

<script>
import Modal from '@/components/modal/modal';
import viewChart from '@/components/chart/view-chart';
export default {
    name:"classModal",
    components: {
        Modal,
        viewChart
    },
    props:{
        modelShow:Boolean,
        modelSize:String,
        // dataList:{
        //     type:Array,
        //     default:() => {
        //         return [
        //             {
        //                 name:'福州第三十中学',
        //                 number:1,              //课堂考勤次数
        //                 abnormityNum:100,      //异常
        //                 clockinginNum:200      //考勤
        //             },
        //             {
        //                 name:'福州第二十中学',
        //                 number:5,              //课堂考勤次数
        //                 abnormityNum:450,      //异常
        //                 clockinginNum:100      //考勤
        //             },
        //             {
        //                 name:'福州第十中学',
        //                 number:3,              //课堂考勤次数
        //                 abnormityNum:40,      //异常
        //                 clockinginNum:200      //考勤
        //             }
        //         ]
        //     }
        // }
    },
    data(){
        return{
            isReload: false,
            dataList: [],
            option:{
                title: {
                    text: '',
                    textStyle: {
                        color: "#fff"
                    }
                },
                color: ['#c33','#1096DD'],
                tooltip: {
                    // trigger: 'axis',
                    // axisPointer: {
                    //     type: 'shadow'
                    // },
                    trigger: 'item',
                    formatter: "{a}: {c}"
                },
                legend: {
                    data: ['课堂考勤异常人数', '参与课堂考勤人次'],
                    textStyle: {
                        color: "#fff"
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    splitLine: {lineStyle:{color: "#354156"}},
                    axisLine: {  
                        lineStyle: {
                            color: "#354156",
                        }
                    }
                },
                yAxis: {
                    type: 'category',
                    axisLine: {  
                        lineStyle: {
                            color: "#354156",
                        }
                    },
                    data: [],
                    axisLabel: {
                        formatter: function (value) {
                            
                            let strPosition = value.indexOf('|');
                            let startStr = value.substring(0,strPosition);
                            let endStr = value.substring(strPosition+1,value.legend);
                            let arr = [startStr, "课堂考勤次数：" + endStr];
                            return arr.join("\n");
                        },
                        lineHeight: 20,
                        textStyle:{
                            color:"#fff", //刻度颜色
                            fontSize:14   //刻度大小
                        },
                    }
                },
                series: []
            }
        }
    },
    computed:{
        options(){
            var option = this.option;
            let titleList = option.legend.data;

            titleList.forEach(item=>{ 
                option.series.push({
                    name: item,
                    type: 'bar',
                    animationDuration: 2800,
                    animationEasing: "cubicInOut",
                    label: {
                        show: true,
                        position: 'right'
                    },
                    data: []
                })
            }) 
           
            if(this.dataList.length > 0){
                this.dataList.forEach(item => {
                    option.yAxis.data.unshift(item.schoolName+'|'+ (item.checkTimes ? item.checkTimes: 0));
                    option.series[0].data.unshift(item.totalErrorNum);
                    option.series[1].data.unshift(item.checkStuNum);
                })
            }
            return option
        },
        countyUserInfo () {
            return this.$store.state.user.countyUserInfo
        }
    },
    methods: {
        open () {
            Object.assign(this.$data, this.$options.data())
            this.getTodayData()
        },
        getTodayData () {
            this.$store.dispatch('classAttend/GetTodayData', {eduId: this.countyUserInfo.organizationIdStr}).then(
                res => {
                    if (res) {
                        this.isReload = false
                        this.dataList = res
                        this.isReload = true
                    }
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-container{
    box-sizing: border-box;
    padding:0.3rem 1.78rem;
    .modal-title{
        text-align: center;
        color:#fff;
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
    }
    .modal-itemData{
        h4{
            font-size:0.16rem;
            color:#00A2FF;
            box-sizing: border-box;
            font-weight: 600;
        }
        .viewChart{
            margin-top:0.2rem;
            height: 5.2rem;
            overflow: auto;
        }
    }
}
</style>