<template>
    <div class="schoolModal">
       <modal
        :modalScrollbar="true"
        :modelSize="'max'"
        :modelShow="modelShow" 
        @open="open"
        @handelClose="$emit('handelClose')">
            <template slot="body">
                <div class="loading" v-loading="!isRefresh" element-loading-background="rgba(0, 0, 0, 0.2)"></div>
                <div class="modal-container">
                    <div class="modal-title">实时在校数据</div>
                    <div class="modal-itemData">
                        <div class="viewChart" v-if="isRefresh">
                            <view-chart 
                                :chartId="'schoolChart'" 
                                :option="options" 
                                :width="'13rem'" 
                                :height="height + 'rem'">
                            </view-chart>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
    </div>    
</template>

<script>
import Modal from '@/components/modal/modal';
import viewChart from '@/components/chart/view-chart';
import {getSchoolDataDetail} from '@/api/county';
export default {
    name:"schoolModal",
    components: {
        Modal,
        viewChart
    },
    props:{
        modelShow:Boolean,
        modelSize:String
    },
    data(){
        return{
            isRefresh:false,
            data:[
                {
                    currentStudentNum:0,
                    currentTeacherNum:0,
                    schoolName: "",
                    studentNum: 0,
                    teacherNum: 0
                }
            ],
            option:{
                title: {
                    text: '',
                    textStyle: {
                        color: "#fff"
                    }
                },
                color: ['#38a7fc','#77e6eb','#fd6631','#f7b74b'],
                tooltip: {
                    trigger: 'item',
                    formatter: "{a}: {c}"
                },
                legend: {
                    data: ['学生数', '学生在校人数','教职工数','教职工在校人数'],
                    textStyle: {
                        color: "#fff"
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    splitLine: {lineStyle:{color: "#354156"}},
                    axisLine: {  
                        lineStyle: {
                            color: "#354156",
                        }
                    }
                },
                yAxis: {
                    type: 'category',
                    axisLine: {  
                        lineStyle: {
                            color: "#354156",
                        }
                    },
                    data: [],
                    axisLabel: {
                        textStyle:{
                            color:"#fff", //刻度颜色
                            fontSize:14   //刻度大小
                        },
                    }
                },
                series: [

                ]
            }
        }
    },
    computed:{
        height () {
            if(this.data.length > 5){
                return 1.3 * this.data.length
            }else{
                return 6.3
            }
            
        },
        countyUserInfo(){
          return this.$store.state.user.countyUserInfo
        },
        options(){
            var option = this.option;
            let titleList = option.legend.data;
            option.series = [];
            titleList.forEach(item=>{ 
                option.series.push({
                    name: item,
                    type: 'bar',
                    animationDuration: 2800,
                    animationEasing: "cubicInOut",
                    label: {
                        show: true,
                        position: 'right'
                    },
                    data: []
                })
            }) 
           
            if(this.data.length > 0){
                option.yAxis.data = []
                this.data.forEach(item => {
                    option.yAxis.data.unshift(item.schoolName);
                    option.series[0].data.unshift(item.studentNum);
                    option.series[1].data.unshift(item.currentStudentNum);
                    option.series[2].data.unshift(item.teacherNum);
                    option.series[3].data.unshift(item.currentTeacherNum);
                })
            }
            return option
        }
    },
    watch:{
        modelShow(){
            if(!this.modelShow){
                this.isRefresh = false;
            }
        }
    },
    methods: {
        getData(){
            const params = {
                eduId:this.countyUserInfo.organizationIdStr
            }
            getSchoolDataDetail(params).then(res => {
                if(res.status == 200 && res.data != null){
                   this.data = res.data;
                }
                this.isRefresh = true;
            }).catch(error =>{
                this.isRefresh = true;
            })
        },
        open () {
            this.getData()
        } 
    }
}
</script>

<style lang="scss" scoped>
.loading{
    height:100%;
    width:100%;
    position: fixed;
    top:0;
}
.modal-container{
    box-sizing: border-box;
    padding:0.3rem 0;
    .modal-title{
        text-align: center;
        color:#fff;
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
    }
    .modal-itemData{
        height:auto;
        min-height:100%;
        h4{
            font-size:0.16rem;
            color:#00A2FF;
            box-sizing: border-box;
            padding-left: 0.6rem;
            font-weight: 600;
        }
        .viewChart{
            margin-top:0.4rem;
        }
    }
}
</style>