<template>
  <div>
    <!-- 实时在校数据 -->
    <stateSchool
      v-if="template && template.templateCode == 'inschoolData'"
      class="party"
    ></stateSchool>
    <!--校园报警 -->
    <callPolice
      v-if="template && template.templateCode == 'alarmInfoData'"
      ref="callPolice"
      class="party"
    ></callPolice>
    <!-- 体温监测 -->
    <temperature
      v-if="template && template.templateCode == 'temperatureData'"
      class="party"
    ></temperature>
    <!-- 云上党建 -->
    <cloudParty
      v-if="template && template.templateCode == 'partyBuildData'"
      class="party"
    ></cloudParty>
    <!-- 宿舍管理 -->
    <dormManage
      v-if="template && template.templateCode == 'dormData'"
      class="party"
    ></dormManage>
    <!-- 课堂考勤 -->
    <classAttend
      v-if="template && template.templateCode == 'studentCheckData'"
      class="party"
    ></classAttend>
    <!-- 健康监测 -->
    <defence
      v-if="template && template.templateCode == 'temperatureDatas'"
      class="party"
    ></defence>
    <!-- 健康监测 -->
    <dangerous
      v-if="template && template.templateCode == 'dangerArea'"
      class="party"
    ></dangerous>
    
  </div>
</template>
<script>
import classAttend from "@/components/dataPart/classAttend";
import cloudParty from "@/components/dataPart/cloudParty";
import dormManage from "@/components/dataPart/dormManage";
import callPolice from "@/components/dataPart/callPolice";
import stateSchool from "@/components/dataPart/stateSchool";
import temperature from "@/components/dataPart/temperature";
import defence from "@/components/dataPart/defence";
import dangerous from "@/components/dataPart/dangerous";
export default {
  components: {
    callPolice,
    classAttend,
    cloudParty,
    dormManage,
    stateSchool,
    temperature,
    defence,
    dangerous
  },
  props: {
    template: Object,
    default: function () {
      return {};
    },
  },

  mounted() {
    
  },
};
</script>
<style scoped>
</style>