<template>
    <div class="contianer" @click="toDestop">
        <top ref="topCp"></top>
        <alarmScroll></alarmScroll>
        <left ref="leftCp"></left>
        <bottomMenu ref="bottomCp"></bottomMenu>

        <!-- <centerMap></centerMap> -->
        <div class="left-mo mo">
            <div class="party">
               
                <block :template='blockList[0]'></block>
            </div>
            <div class="party">
                
                <block :template='blockList[2]'></block>
            </div>
            <div class="party">
              
                <block :template='blockList[4]'></block>
            </div>
        </div>
        <div class="center-mo mo">
             <!-- 地图 -->
            <centerMap   class="party"></centerMap>
             <!-- 辖区基础数据 -->
            <partBaseData  class="party"></partBaseData>
        </div>
        <div class="right-mo mo">
            <div class="party">
             
                <block :template='blockList[1]'></block>
            </div>
            <div class="party">
               
                <block :template='blockList[3]'></block>
            </div>
            <div class="party">
             
                <block :template='blockList[5]'></block>
            </div>
        </div>
        <div class="clear"></div>

         <!--首页弹窗-->
        <indexModal
            :modelShow="indexModelShow" 
            @handelClose="$store.state.county.indexModelShow = false">
        </indexModal>

        <!--云上党建-->
        <partyModal
            :modelShow="partyModalShow" 
            @handelClose="$store.state.county.partyModalShow = false">
        </partyModal>

        <!--课堂考勤-->
        <classModal
            :modelShow="classModalShow" 
            @handelClose="$store.state.county.classModalShow = false">
        </classModal>

        <!--实时在校数据-->
        <schoolModal
            :modelShow="schoolModalShow" 
            @handelClose="$store.state.county.schoolModalShow = false">
        </schoolModal>

        <!--体温监测-->
        <temperatureModal
            :modelShow="temperatureModalShow" 
            @handelClose="$store.state.county.temperatureModalShow = false">
        </temperatureModal>

        <!--宿舍管理-->
        <dormModal
            :modelShow="dormModalShow" 
            @handelClose="$store.state.county.dormModalShow = false">
        </dormModal>
         <!--健康监测  defenceModalShow-->
        <defenceModal
            :modelShow="defenceModalShow" 
            @handelClose="$store.state.county.defenceModalShow = false">
        </defenceModal>

        <dangerousModal :modelShow="$store.state.county.dangerModelShow" @close="$store.state.county.dangerModelShow = false"></dangerousModal>
          <!--校园报警-->
        <alarmModal
            :modelShow="alarmModalShow" 
            @handelClose="$store.state.county.alarmModalShow = false">
        </alarmModal>
        <!--首页设置-->
        <indexSetModal
            :modelShow="indexSetShow" 
            @setingChange="setingChange"
            @handelClose="$store.state.county.indexSetShow = false">
        </indexSetModal>
        <!-- 预警设置 -->
        <warningSet
            :modelShow="warningSetShow" 
            @handelClose="$store.state.county.warningSetShow = false">
        </warningSet>
       
    </div>
</template>
<script>
import top from './top'
import alarmScroll from './alarmScroll'
import left from './left'
import bottomMenu from './bottomMenu'
import centerMap from '@/components/dataPart/centerMap'
import callPolice from '@/components/dataPart/callPolice'
import classAttend from '@/components/dataPart/classAttend'
import cloudParty from '@/components/dataPart/cloudParty'
import dormManage from '@/components/dataPart/dormManage'
import partBaseData from '@/components/dataPart/partBaseData'
import stateSchool from '@/components/dataPart/stateSchool'
import temperature from '@/components/dataPart/temperature'
import defence from '@/components/dataPart/defence'

import indexModal from '@/components/modal/indexModal';
import partyModal from '@/components/modal/partyModal';
import classModal from '@/components/modal/classModal';
import schoolModal from '@/components/modal/schoolModal';
import temperatureModal from '@/components/modal/temperatureModal';
import dormModal from '@/components/modal/dormModal';
import indexSetModal from '@/components/modal/indexSetModal';
import warningSet from '@/components/modal/warningSet';
import alarmModal from '@/components/modal/alarmModal';
import defenceModal from '@/components/modal/defenceModal'
import dangerousModal from "@/components/modal/danger/dangerousModal";
import block from './block.vue'

import cookie from '@/util/cookie'
import refreshToken from '@/util/refreshToken'
export default {
    components: {
        top, alarmScroll, left, bottomMenu, centerMap, callPolice, classAttend, cloudParty,
        dormManage, partBaseData, stateSchool, temperature, defence,

        block,

        indexModal,
        partyModal,
        classModal,
        schoolModal,
        temperatureModal,
        dormModal,
        indexSetModal,
        warningSet,
        alarmModal,
        defenceModal,
        dangerousModal
    },
    computed: {
        indexModelShow () {
            return this.$store.state.county.indexModelShow
        },
        partyModalShow () {
            return this.$store.state.county.partyModalShow
        },
        classModalShow () {
            return this.$store.state.county.classModalShow
        },
        schoolModalShow () {
            return this.$store.state.county.schoolModalShow
        },
        temperatureModalShow () {
            return this.$store.state.county.temperatureModalShow
        },
        dormModalShow () {
            return this.$store.state.county.dormModalShow
        },
        indexSetShow () {
            return this.$store.state.county.indexSetShow
        },
        warningSetShow () {
            return this.$store.state.county.warningSetShow
        },
        alarmModalShow () {
            return this.$store.state.county.alarmModalShow
        },
        defenceModalShow () {
            return this.$store.state.county.defenceModalShow
        },
         countyUserInfo () {
            return this.$store.state.user.countyUserInfo
        }
    },
    data(){
        return {
            blockList: [],
            setting: {},
            templateList: []
        }
    },
    methods: {
        toDestop () {
           
            this.$refs.leftCp.close()
            this.$refs.bottomCp.close()
        },
         // 获取设置列表
        async getBaseSet () {
            var params = {
                eduId: this.countyUserInfo.organizationIdStr,
                settingsType: 1
            }
            let res = await this.$store.dispatch('indexSet/GetBaseSet', params)
           
             for(var set of res){
                if(set.valueType == 4){
                    this.setting = set
                }
             }
            
            if(!this.setting.settingsValue){
                
                this.setting.settingsValue = {}
                for(var i = 0; i < 6; i++){
                    var temp = this.templateList[i]
                    this.setting.settingsValue[i + 1] = {
                        templateName: temp.templateName,
                        templateCode: temp.templateCode,
                        templateId: temp.templateId
                    }
                }

            }

          
           
            var list = []
            for(var key in this.setting.settingsValue){
                
                list.push(this.setting.settingsValue[key])
                
            }

            this.blockList = JSON.parse(JSON.stringify(list))

             
        },
        // 获取模块列表
        async getTemplate () {
            let res = await this.$store.dispatch('indexSet/GetTemplate')
            this.templateList = res
            
             console.log(res)
        },

        async setingChange(){
            await this.getTemplate()

            await this.getBaseSet()
        }
    },
    async mounted () {
        console.log('county index...')

        refreshToken.refreshLoop()

        this.setingChange()
        
    }   
}
</script>
<style scoped>
.contianer{
    height: 100%; 
    overflow: hidden; position: relative;
    background: url(../../assets/img/body.png);
}
.mo{
    float: left;
}
.left-mo{
    margin: 0 0 0 0.56rem;
}
.center-mo{
    padding: 0.12rem 0.22rem 0 0.22rem;
}
.clear{
    clear: both;
}
.party{
    margin: 0 0 0.2rem 0;
}
</style>