<template>
    <div @click="showDetail">
        <div class="box">
            <p class="partName">
                宿舍管理
            </p>
            <div class="innerBox">   
                <div class="left">
                    <p class="rowName">
                        寄宿类学校数：
                        <span class="numColor">
                            {{detail.boarderSchoolNum}}
                        </span>
                    </p>
                    <p class="rowName">
                        宿舍楼数：
                        <span class="numColor">
                            {{detail.dormBuildNum}}
                        </span>
                    </p>
                    <p class="rowName">
                        寄宿生数：
                        <span class="numColor">
                            {{detail.boarderNum}}
                        </span>
                    </p>
                    <p class="rowName">
                        寄宿生在寝率：
                        <span class="numColor">
                            {{detail.inDormProportion}}%
                        </span>
                    </p>
                </div>
                <div class="right">
                    <div class="anBox anTwo">
                        <pie :data='data'></pie>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import pie from './echarts/pie'
import annular from '@/components/annular'
import {refreshTime} from '@/config/config'
export default {
    components: {
        pie, annular
    },
    data () {
        return {
            detail: 
            {
                boarderNum: 0,
                boarderSchoolNum: 0,
                dormBuildNum: 0,
                inDormNum: 0,
                inDormProportion: 0
            },
            data: []
        }
    },
    computed: {
        countyUserInfo () {
            return this.$store.state.user.countyUserInfo
        }
    },
    methods: {
        showDetail () {
            this.$store.state.county.dormModalShow = true 
        },
        getData () {
            this.$store.dispatch('dormManage/GetData', {eduId: this.countyUserInfo.organizationIdStr}).then(
                res => {
                    if (res) {
                        this.detail = res
                        var arr = []
                        arr.push({name:"在寝",value: res.inDormNum, color: '#0691EA'})
                        var notInDormNum = res.boarderNum - res.inDormNum
                        arr.push({name:"未在寝",value: notInDormNum, color: '#ED7F26'})
                        this.data = arr
                    } 
                }
            )
        },
        refresh () {
            setTimeout(() => {
                this.getData()
                this.refresh()
            }, refreshTime)
        }
    },
    mounted () {
        this.getData()
        this.refresh()
    }
}
</script>
<style scoped>
    *{
        color: #fff;
        font-size: 0.14rem;
    }
    .box{
        padding: 0 0.38rem;
        width: 5.04rem;
        height: 2.44rem;
        background-image: url('./img/smallBk.png');
        background-size: 100%;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
    }
    .partName{
        text-align: center;
        font-weight: bold;
        font-size: 0.16rem;
    }
    .left, .right{
        display: inline-block;
    }
    .left{
        padding: 0.54rem 0;
        vertical-align: top;
        margin-top: 0;
    }
    .right{
        padding-top: 0.1rem;
        float: right;
    }
    .rowName{
        line-height: 30px;
    }
    .anBox{
        display: inline-block;
        width:  1.68rem;
        height: 1.68rem;
    }
    .anTitle{
        color: #00A2FF;
        margin-top: 0.16rem;
        text-align: center;
    }
    .anTwo{
        margin-left: 0.4rem;
    }
    .numColor{
        color: #00F9FF;
        font-weight: bold;
    }
</style>