<template>
    <div class="">
       <modal
        :modelSize="'max'"
        :modelShow="modelShow" 
        @open="open"
        @handelClose="$emit('handelClose')">
            <template slot="body">
                <div class="modal-container partyModal">
                    <div class="modal-title">云上党建</div>
                    <div class="modal-itemData">
                        <h4>今日党建数据</h4>
                        <div class="viewChart" v-if="isReload">
                            <view-chart 
                                :chartId="'partyChart'" 
                                :option="options" 
                                :width="'100%'" 
                                :height="charHeight">
                            </view-chart>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
    </div>    
</template>

<script>
var dataList = []
for(var i = 0; i < 20; i++){
    dataList.push({
        avgStudyTime: 1,
            completeNum: 100,
            schoolName: "福州第三十中学",
            signUpNum: 100
    })
}


import Modal from '@/components/modal/modal';
import viewChart from '@/components/chart/view-chart';
export default {
    name:"partyModal",
    components: {
        Modal,
        viewChart
    },
    props:{
        modelShow:Boolean,
        modelSize:String,
        // dataList:{
        //     type:Array,
        //     default:() => {
        //         return [
        //             {
        //                 name:'福州第三十中学',
        //                 time:'2小时26分16秒',
        //                 registerNum:450,      //签到人数
        //                 accomplishNum:200     //练习完成人数
        //             },
        //             {
        //                 name:'福州第二十中学',
        //                 time:'1小时20分36秒',
        //                 registerNum:800,
        //                 accomplishNum:320
        //             },
        //             {
        //                 name:'福州第十中学',
        //                 time:'10小时20分36秒',
        //                 registerNum:1000,
        //                 accomplishNum:500
        //             }
        //         ]
        //     }
        // }
    },
    data(){
        return{
            dataList: dataList,
            isReload: false,
            option:{
                title: {
                    text: '',
                    textStyle: {
                        color: "#fff"
                    }
                },
                color: ['#1096DD','#C4A759'],
                tooltip: {
                    // trigger: 'axis',
                    // axisPointer: {
                    //     type: 'shadow'
                    // },
                    trigger: 'item',
                    formatter: "{a}: {c}"
                },
                legend: {
                    data: ['签到人数', '练习完成人数'],
                    textStyle: {
                        color: "#fff"
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    splitLine: {lineStyle:{color: "#354156"}},
                    axisLine: {  
                        lineStyle: {
                            color: "#354156",
                        }
                    }
                },
                yAxis: {
                    type: 'category',
                    axisLine: {  
                        lineStyle: {
                            color: "#354156",
                        }
                    },
                    data: [],
                    axisLabel: {
                        formatter: function (value) {
                            let strPosition = value.indexOf('|');
                            let startStr = value.substring(0,strPosition);
                            let endStr = value.substring(strPosition+1,value.legend);
                            let arr = [startStr, "人均学习时长：" + endStr];
                            return arr.join("\n");
                        },
                        lineHeight: 20,
                        textStyle:{
                            color:"#fff", //刻度颜色
                            fontSize:14   //刻度大小
                        },
                    }
                },
                series: []
            }
        }
    },
    computed:{
        charHeight(){
            var height = this.dataList.length * 0.5
            if(height < 6){
                height = 6
            }
            return height + 'rem'
        },
        options () {
            var option = this.option;
            let titleList = option.legend.data;

            titleList.forEach(item=>{ 
                option.series.push({
                    name: item,
                    type: 'bar',
                    animationDuration: 2800,
                    animationEasing: "cubicInOut",
                    data: []
                })
            }) 
           
            if(this.dataList.length > 0){
                this.dataList.forEach(item => {
                    option.yAxis.data.unshift(item.schoolName+'|'+item.avgStudyTime);
                    option.series[0].data.unshift(item.signUpNum);
                    option.series[1].data.unshift(item.completeNum);
                })
            }
            return option
        },
        countyUserInfo () {
            return this.$store.state.user.countyUserInfo
        }
    },
    methods: {
        open () {
            Object.assign(this.$data, this.$options.data())
            this.getTodayData()
        },
        getTodayData () {
            this.$store.dispatch('cloudParty/GetTodayData', {eduId: this.countyUserInfo.organizationIdStr}).then(
                res => {
                    if (res) {
                        
                        this.isReload = false
                        this.dataList = res
                        this.isReload = true
                    }
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-container{
    box-sizing: border-box;
    padding:0.3rem 1.78rem;
    .modal-title{
        text-align: center;
        color:#fff;
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
    }
    .modal-itemData{
        h4{
            font-size:0.16rem;
            color:#00A2FF;
            box-sizing: border-box;
            font-weight: 600;
        }
        .viewChart{
            margin-top:0.2rem;
          
            overflow: auto;
        }
    }
}
</style>


