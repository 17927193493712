<template>
    <div class="alarmModal">
       <modal
        :modelSize="'max'"
        :modelShow="modelShow" 
        @open="handelOpen"
        @handelClose="$emit('handelClose')">
            <template slot="body">
                <div class="loading" v-if="!isRefresh" v-loading="!isRefresh" element-loading-background="rgba(0, 0, 0, 0.2)"></div>
                <div class="modal-container">
                    <div class="modal-title">校园报警</div>
                    <div class="modal-list">
                        <h4>最新报警信息</h4>
                        <ul class="items itemsOne" ref="itemsOne">
                            <li class="item" v-for="(item,index) of itemsOne" :key="index">
                                <div class="time">{{item.alarmTime | formatDateTime('yyyy-MM-d HH:mm:ss')}}</div>
                                <div class="name">{{item.schoolName}}{{item.alarmMessage}}</div>
                                <div class="alarmType">
                                    <span v-if="item.alarmType == '1' ? true : false">学生卡报警</span>
                                    <span v-if="item.alarmType == '2' ? true : false">报警柱报警</span>
                                </div>
                                <div class="alarmState2" v-if="!item.readType">
                                    <i></i>
                                    <span>未接警</span>
                                </div>
                                <div class="alarmState" v-else>已接警</div>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-list">
                        <h4>辖区学校报警数据</h4>
                        <ul class="items itemsTwo" ref="itemsTwo" style="height:2.8rem">
                            <li class="item" v-for="(item,index) of itemsTwo" :key="index">
                                <div class="item-title">{{item.schoolName}}</div>
                                <div class="clearfix"></div>
                                <div class="item-cont">
                                    <div class="itemBox">
                                        <div class="name">报警柱数量</div>
                                        <div class="num">{{item.alarmDeviceNum}}</div>
                                    </div>
                                    <div class="itemBox">
                                        <div class="name">学生卡数量</div>
                                        <div class="num">{{item.cardNum}}</div>
                                    </div>
                                    <div class="itemBox">
                                        <div class="name">历史报警数量</div>
                                        <div class="num">{{item.historyAlarmNum}}</div>
                                    </div>
                                    <div class="itemBox">
                                        <div class="name">近七日报警数量</div>
                                        <div class="num">{{item.latestSevenDayAlarmNum}}</div>
                                    </div>
                                    <div class="itemBox itemBox2">
                                        <div class="name">最近一次报警</div>
                                        <div class="num">{{item.latestAlarmTime | formatDateTime('yyyy-MM-d HH:mm:ss')}}</div>
                                    </div>
                                    <div class="itemBox itemBox2">
                                        <div class="name">平均接警时间</div>
                                        <div class="chartBox">
                                            <div class="alarmChart" v-if="isRefresh">
                                                <div>
                                                    <alarmChart :data="item.avgReadedTime"></alarmChart>
                                                </div>
                                            </div>
                                            <div class="chartTime">{{item.avgReadedTime | timestampZh}}</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </modal>
    </div>    
</template>

<script>
import Modal from '@/components/modal/modal';
import alarmChart from '@/components/modal/alarmChart';
import {getSchoolNewestWarn,getSchoolWarnData} from '@/api/county';
export default {
    name:"alarmModal",
    components: {
        Modal,
        alarmChart
    },
    props:{
        modelShow:Boolean,
        modelSize:String
    },
    data(){
        return {
            isRefresh:false,
            itemsOne:[],
            itemsTwo:[]
        }
    },
    computed:{
        countyUserInfo(){
          return this.$store.state.user.countyUserInfo
        }
    },
    watch:{
        modelShow(){
            if(!this.modelShow){
                this.isRefresh = false;
                this.itemsOne = [];
                this.itemsTwo = [];
                this.handelRollTop(this.$refs.itemsOne);
                this.handelRollTop(this.$refs.itemsTwo);
            }
        }
    },
    methods: {
        handelSchoolNewestWarn(){
            const params = {
                eduId:this.countyUserInfo.organizationIdStr
            }
            getSchoolNewestWarn(params).then(res=>{
                if(res.status == 200 && res.data != null){
                    this.itemsOne = res.data;
                }
                this.isRefresh = true;
            }).catch(error =>{
                this.isRefresh = true;
            })
            
        },
        handelSchoolWarnData(){
            const params = {
                eduId:this.countyUserInfo.organizationIdStr
            }
            getSchoolWarnData(params).then(res=>{
                if(res.status == 200 && res.data != null){
                    this.itemsTwo = res.data; 
                } 
                this.isRefresh = true;
            }).catch(error =>{
                this.isRefresh = true;
            })
        },
        handelRollTop(dom){
            dom.scrollTop = 0;
        },
        handelOpen(){
            this.handelSchoolNewestWarn();
            this.handelSchoolWarnData();
        }
    },
}
</script>

<style lang="scss" scoped>
.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}
.loading{
    height:100%;
    width:100%;
    position: fixed;
    top:0;
}
.modal-container{
    box-sizing: border-box;
    padding:0.3rem 1.6rem;
    .modal-title{
        text-align: center;
        color:#fff;
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
    }
    .modal-list{
        margin-top:0.5rem;
        h4{
            font-size:0.16rem;
            font-weight: 700;
            color:#00A2FF;
        }
        .items{
            height:2.1rem;
            overflow:hidden;
            overflow-y:auto;
        }
        .items::-webkit-scrollbar {/*滚动条整体样式*/
            width: 0.1rem;    
            height: 0.1rem;
        }
        .items::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
            background: #395283;
        }
        .items::-webkit-scrollbar-track {/*滚动条里面轨道*/
            background: #152952;
            border-radius:0.1rem;
        }
        .itemsOne{
            margin-top:0.3rem;
            .item{
                display: flex;
                color:#B8C8E9;
                margin-bottom: 0.24rem;
                font-size: 0.16rem;
                .time{
                    width:21%;
                }
                .name{
                    width:45%;
                    box-sizing: border-box;
                    padding-right:4%;
                }
                .alarmType{
                    width:15%;
                }
                .alarmState{
                    width: 19%;
                    text-align: center;
                }
                .alarmState2{
                    color:#fff;
                    width: 19%;
                    text-align: center;
                    i{
                        display:inline-block;
                        width:0.18rem;
                        height:0.18rem;
                        background:url(../../assets/img/modal-icon4.png) no-repeat;
                        background-size: cover;
                        vertical-align: middle;
                    }
                    span{
                        vertical-align: middle;
                    }
                }
            }
        }
        .itemsTwo{
            .item{
                .item-title{
                    line-height:0.32rem;
                    padding:0 0.5rem 0 0.15rem;
                    background: #08245b; 
                    background: -moz-linear-gradient(left,  #08245b 50%, #011130 100%); 
                    background: -webkit-linear-gradient(left,  #08245b 50%,#011130 100%); 
                    background: linear-gradient(to right,  #08245b 50%,#011130 100%); 
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#08245b', endColorstr='#011130',GradientType=1 ); 
                    color:#fff;
                    font-size: 0.14rem;
                    font-weight: 700;
                    float: left;
                    margin-top: 0.3rem;
                }
                .item-cont{
                    display: flex;
                    height:0.6rem;
                    margin-top:0.15rem;
                    .itemBox{
                        height:100%;
                        width: 14%;
                        text-align: center;
                        box-sizing: border-box;
                        border-right:1px solid #147ECF;
                        .name{
                            color:#B8C8E9;
                            font-size:0.14rem;
                            margin-top: 0.04rem;
                        }
                        .num{
                            font-size: 0.16rem;
                            font-weight:700;
                            color:#00F9FF;
                            margin-top: 0.13rem;
                        }
                        .chartBox{
                            width:100%;
                            height: 0.41rem;
                            display: flex;
                            .alarmChart{
                                width: 1rem;
                                height: 0.38rem;
                                position: relative;
                                div{
                                    transform: scale(0.5);
                                    position: absolute;
                                    top: -0.18rem;
                                    left: 0rem;
                                }
                            }
                            .chartTime{
                                font-size: 0.16rem;
                                font-weight:700;
                                color:#00F9FF;
                                flex:1;
                                display: flex;
                                //justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                    .itemBox2{
                        height:100%;
                        width: 22%;
                        text-align: center;
                        box-sizing: border-box;
                        border-right:1px solid #147ECF;
                    }
                    .itemBox2:last-child{
                        border-right:none;
                    }
                }
            }
        }
    }
}
</style>