<template>
    <div class="dormModal">
       <modal
        :modelSize="'max'"
        :modelShow="modelShow" 
        @open="open"
        @handelClose="$emit('handelClose')">
            <template slot="body">
                <div class="dormModal">
                    <div class="modal-title">宿舍管理</div>
                    <div class="modal-list">
                        <h4>宿舍数据明细</h4>
                        <el-table
                            :data="list" 
                            style="width: 100%"
                            height="520"
                            ref="dormTable">
                            <el-table-column 
                                prop="schoolName" 
                                label="学校名称">
                            </el-table-column>
                            <el-table-column 
                                prop="dormBuildNum" 
                                label="宿舍楼数">
                            </el-table-column>
                            <el-table-column label="在寝比例" width="230">
                                <template slot-scope="scope">
                                    <dormChart
                                        v-if="modelShow" 
                                        :data="[`${scope.row.boarderNum}`,`${scope.row.inDormNum}`]">
                                    </dormChart>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </template>
        </modal>
    </div>    
</template>

<script>
import Modal from '@/components/modal/modal';
import dormChart from '@/components/modal/dormChart';
export default {
    name:"dormModal",
    components: {
        Modal,
        dormChart
    },
    props:{
        modelShow:Boolean,
        modelSize:String
    },
    data () {
        return {
            pageNum: 1,
            pageSize: 10,
            isMore: true,
            list:[
                // {
                //     schoolName:'福州市晋安区第二实验小学',
                //     dormNum:5,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                // {
                //     schoolName:'福州市晋安区第二实验小学',
                //     dormNum:4,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                //     {
                //     schoolName:'福州市晋安区第二实验小学',
                //     dormNum:2,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                //     {
                //     schoolName:'福州市晋安区第二实验小学',
                //     dormNum:5,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                // {
                //     schoolName:'福州市晋安区第二实验小学',
                //     dormNum:8,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                //     {
                //     schoolName:'福州市晋安区第三实验小学',
                //     dormNum:8,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                //     {
                //     schoolName:'福州市晋安区第二实验小学',
                //     dormNum:5,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                // {
                //     schoolName:'福州市晋安区第二实验小学',
                //     dormNum:4,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                //     {
                //     schoolName:'福州市晋安区第二实验小学',
                //     dormNum:2,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                //     {
                //     schoolName:'福州市晋安区第二实验小学',
                //     dormNum:5,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                // {
                //     schoolName:'福州市晋安区第二实验小学',
                //     dormNum:8,            //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // },
                //     {
                //     schoolName:'福州市晋安区第三实验小学',
                //     dormNum:10,           //宿舍楼数
                //     offlineNum:1000,      //寄宿生人数
                //     onlineNum:500         //在寝人数
                // }
            ]
        }
    },
    computed: {
        countyUserInfo () {
            return this.$store.state.user.countyUserInfo
        }
    },
    methods: {
        open() {
            Object.assign(this.$data, this.$options.data())
            this.getList()
            // this.handelRoll(this.$refs.dormTable.bodyWrapper)
        },
        getList(){
           this.$store.dispatch('dormManage/GetTodayData', {eduId: this.countyUserInfo.organizationIdStr}).then(
               res => {
                    if (res) {
                       this.list = res
                    }
               }
           )
        },
        loadmore () {
            this.pageNum ++
            this.getList(this.pageNum)
        },
        handelRoll(dom){
            dom.addEventListener('scroll', () => {
                let scrollTop = dom.scrollTop;                                      // 滚动距离
                let windowHeight = dom.clientHeight || dom.clientHeight;            // 可视区的高度
                let scrollHeight = dom.scrollHeight || dom.scrollHeight;            // 滚动条的总高度
                if (scrollTop + windowHeight === scrollHeight) {
                    this.loadmore()                       
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.dormModal{
    box-sizing: border-box;
    padding:0.3rem 1.78rem;
    .modal-title{
        text-align: center;
        color:#fff;
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
    }
    .modal-list{
        h4{
            font-size:0.16rem;
            color:#00A2FF;
            box-sizing: border-box;
            font-weight: 600;
        }
        .viewChart{
            overflow: auto;
        }
    }
}
</style>
<style>
.dormModal .modal-list .el-table, 
.dormModal .modal-list .el-table tr, 
.dormModal .modal-list .el-table td, 
.dormModal .modal-list .el-table th {
    background-color: transparent!important;
    border: none;
    text-align: center;
}
.dormModal .modal-list .el-table{
    margin-top:0.35rem;
}
.dormModal .modal-list .el-table::before{
    background-color: transparent!important;
}
.dormModal .modal-list .el-table th{
    font-size:0.16rem;
    color:#fff;
    height: 0.4rem;
}
.dormModal .modal-list .el-table td{
    padding:0.2rem 0;
    font-size:0.14rem;
    color:#B8C8E9;
}
.dormModal .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {/*滚动条整体样式*/
    width: 0.1rem;    
    height: 0.1rem;
}
.dormModal .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    background: #395283;
}
.dormModal .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {/*滚动条里面轨道*/
    background: #152952;
    border-radius:0.1rem;
}
</style>