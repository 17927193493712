<template>
  <div class="moni-con">
    <div class="moni-name">{{monitor.monitorName}}</div>
    <div class="player-p">
      <live-player v-show="url" :video-url="url" live="true" aspect="fullscreen" ></live-player>

      <div v-if="!url" class="no-moniter">
        <img src="@/assets/img/no-monitor.png" class="no-m-img" alt="" srcset="">
        <div class="err-text">
          {{errorText}}
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>

import {socketUrl} from '@/config/config'

import uuid from '@/util/uuid'


export default {
 
  components: {
    
  },
  props: {
    monitor: {
      type: Object,
      default: function(){
        return {}
      }
    },
    schoolId: {
      type: String,
      default: ''
    }
  },
  
  data () {
    return { 
       socket: null,
       url: '',
       errorText: '',
       clientId: uuid()
    }
  },
  methods: {
      player () {
        return this.$refs.videoPlayer.player//自定义播放
        },
       
      connect () { 
        this.socket = new WebSocket(socketUrl + '/platform-edge-device?clientId=' + this.clientId)
        this.socket.onopen = () => {
          console.log('open')
          this.getPullStream()
        }

        this.socket.onmessage = (e) => {
          const data = JSON.parse(e.data)
          console.log(data)
          
          if (data.status === 200) {
            
            this.url = data.flvUrl
          } else {
            this.errorText = data.errorText
          }
        }
      this.socket.onclose = (e) => {
        console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
        console.log('close')
      
      }
      this.socket.onerror = function (error) {
        console.log(error)
      }
    },
    // 发起推流
    async getPullStream () {
      if (this.socket === null || this.socket.readyState !== 1) {
        return this.$message.error('服务器连接中，请稍后重试...')
      }
     
      this.socket.send(JSON.stringify({
        deviceId: this.monitor.monitorId,
        schoolId: this.schoolId,
        clientId: this.clientId,
        devFlag: true
      }))
      
    },
  },
  mounted () {
   
     this.connect()
  },
  destroyed () {
  
    this.socket.close()

   
  }
}
</script>
<style scoped>

.moni-con{
  margin: 14px auto;
}

.player-p{
  position: relative; height: 154px;
  border: 1px solid #027882;
  margin: 0 15px;
}
.moni-name{
  color: #fff;
  padding: 0 0 10px 15px;
}
.no-moniter{
  height: 100%;
  text-align: center; color: #fff; background: #000;
}
.no-m-img{
  display: block; margin: 0 auto; padding: 40px 0 20px 0;
}
.err-text{
  color: #666;
    font-size: 12px;
    text-align: right;
    padding: 20px 10px 0 0;
}
</style>
 <style>
        /* 隐藏暂停按钮 */
        .vjs-play-control {
            display: none !important;
        }
</style>
