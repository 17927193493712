<template>
    <div @click="showDetail">
        <div class="box">
            <p class="partName">
                课堂考勤
            </p>
            <div class="innerBox">   
                <div class="left">
                    <p class="rowName">
                        今日课堂考勤次数：
                        <span class="numColor">
                            {{detail.checkTimes}}
                        </span>
                    </p>
                    <p class="rowName">
                        今日参与课堂考勤人次：
                        <span class="numColor">
                            {{detail.checkStuNum}}
                        </span>
                    </p>
                    <div class="rowName">
                        <p>
                            今日考勤异常最多学校：
                        </p> 
                        <span class="numColor">
                            {{detail.mostErrorSchoolName}}
                        </span>
                    </div>
                </div>
                <div class="right">
                    <div class="anBox anTwo">
                        <pie :data='data' :colorList='colorList'></pie>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import pie from './echarts/pie'
import annular from '@/components/annular'
import {refreshTime} from '@/config/config'
export default {
    components: {
        pie, annular
    },
    computed: {
        countyUserInfo () {
            return this.$store.state.user.countyUserInfo
        }
    },
    data () {
        return {
            detail: {
                checkStuNum: 0,
                checkTimes: 0,
                mostErrorSchoolName: '',
                totalErrorNum: 0
            },
            colorList: ['#0691EA', '#ED7F26', '#90EB89', '#F0702E', '#67C23A'],
            data: [],
        }
    },
    methods: {
        showDetail () {
            this.$store.state.county.classModalShow = true
        },
        getData () {
            this.$store.dispatch('classAttend/GetData', {eduId: this.countyUserInfo.organizationIdStr}).then(
                res => {
                    if (res) {
                        this.detail = res
                        var arr = []
                        if (res.todayCheckData) {
                            res.todayCheckData.forEach(item => {
                                var obj = {name: item.checkStatus, value: item.stuNum}
                                arr.push(obj)
                            })
                        }
                        this.data = arr
                    } 
                }
            )
        },
        refresh () {
            setTimeout(() => {
                this.getData()
                this.refresh()
            }, refreshTime)
        }
    },
    mounted () {
        this.getData()
        this.refresh()
    }
}
</script>
<style scoped>
    *{
        color: #fff;
        font-size: 0.14rem;
    }
    .box{
        padding: 0 0.38rem;
        width: 5.04rem;
        height: 2.44rem;
        background-image: url('./img/smallBk.png');
        background-size: 100%;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
    }
    .partName{
        text-align: center;
        font-weight: bold;
        font-size: 0.16rem;
    }
    .left, .right{
        display: inline-block;
    }
    .left{
        padding: 0.54rem 0;
        vertical-align: top;
        margin-top: 0;
    }
    .right{
        padding-bottom: 0.54rem;
        float: right;
    }
    .rowName{
        line-height: 30px;
    }
    .anBox{
        display: inline-block;
        width: 1.68rem;
        height: 1.92rem;
    }
    .anTitle{
        color: #00A2FF;
        margin-top: 0.16rem;
        text-align: center;
    }
    .numColor{
        color: #00F9FF;
        font-weight: bold;
    }
</style>