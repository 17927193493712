import axios from './axios'
import qs from 'qs'
import {apiUrl} from '@/config/config'

export const sendMessage = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/dataStatistics/sendMessage',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

export const getUnReadMsgList = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/dataStatistics/schoolWarnMessageUnread',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

export const readMsg = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/dataStatistics/schoolWarnMessageRead',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}
