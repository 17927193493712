<template>
    
    <div ref='parent' class="marqu">
        <!-- <div>{{pool.length}}={{list.length}}={{leftOne}}</div> -->
        <div ref="list" class="list" :style="{transform: 'translateX('+leftOne+'px)'}">
            <div v-for="(item,index) in pool" class="alarm-text">
                {{item.warnMessage}}
            </div>
             <!-- <div class="alarm-text">
                1年8月28日  16:32:27  福州市晋安区实验小学  触发  学生卡  报警
            </div> -->
        </div>
       
    </div>
</template>
<script>
// {"eduId":"39f985d2089a474333b9655e72900b4b","messageId":"e9db6d3d3d694b218e2a28eb19149721",
            // "schoolId":"045f049212dd4a70999fc5aa62c8663c","showMap":true,"viewNum":3,
            // "warnMessage":"【在校预警】预警：测试2异常离校人数达到20"}
            
export default {
    props: {
        speed: {
            type: Number,
            default: 1
        }
    },
    data () {
        return {
            leftOne: 0,
            pool: [],
           
        }
    },
    methods: {
        move () {
            // console.log('move', this.leftOne)
             this.leftOne = this.leftOne - this.speed
             
              
                var pw = this.$refs.parent.offsetWidth
                var lw = this.$refs.list.offsetWidth
               

             if(this.leftOne < -lw) {
                // 一轮结束
                this.updateViewNum()
                this.start()
                return
             }
              window.requestAnimFrame(() => {
                this.move()
            })
        },
        add (item) {
            this.pool.push(item)

            if (this.pool.length == 1) {
                this.start()
            } 
        },
        addUnread (list) {
             this.pool = [].concat(list)
             this.start()
        },

        updateViewNum(){
            for(var i = this.pool.length - 1; i >=0; i--) {
                var item = this.pool[i]
                item.viewNum --
                if (item.viewNum <= 0) {
                    this.pool.splice(i, 1)
                } 
            }
        },

        start () {
           

            this.leftOne = this.$refs.parent.offsetWidth

            if (this.pool.length > 0) {
                 this.move()
            }
           
        }
    },
    mounted () {
        
        window.requestAnimFrame = (function() {
            return  window.requestAnimationFrame || 
                    window.webkitRequestAnimationFrame || 
                    window.mozRequestAnimationFrame || 
                    window.oRequestAnimationFrame || 
                    window.msRequestAnimationFrame ||
                function( /* function FrameRequestCallback */ callback, /* DOMElement Element */ element) {
                    return window.setTimeout(callback, 1000 / 60);
                };
        })();

       
    }
}
</script>
<style scoped>
.marqu{
    position: relative; overflow: hidden; white-space: nowrap;
}
.list{
    position: relative; display: inline-block; vertical-align: middle;

}
.alarm-text{
    display: inline-block; vertical-align: middle; margin: 0 0.5rem 0 0; font-weight: bold;
}   
</style>
