<template>
    <modal   :modelSize="'max'"
      :modelShow="modelShow"
      @handelClose="close"
      @open="open" >
        <template slot="body">
            <Amap ref="amap"></Amap>
            <div class="form-p">
               <div class="form-n">选择查询轨迹时间段：</div>
               <el-date-picker
                    v-model="date"
                    @change="getGpsList"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
           </div>
           <div class="danger-list" v-show="dangerRangeList.length > 0">
              
               <div v-for="(item, index) in dangerRangeList" :key="item.id"
               @click="selectRange(index)"
                class="danger-item">
                  
                   <img v-if="selectedDangerIndex == index" class="middle" src="@/assets/img/county/dangerck-a.png" alt="">
                    <img v-else class="middle" src="@/assets/img/county/dangerck.png" alt="">
                   <div class="middle">{{item.name}}</div>
               </div>
           </div>


           <div class="monitor-list" v-if="monitorList.length > 0">
               <div class="monitor-count">区域摄像头数量：{{monitorList.length}}</div>
               <moniter v-for="item in monitorList" :monitor="item" :key="item | random" :schoolId="school.schoolId"></moniter>
           </div>
        </template>
    </modal>
</template>
<script>
import Modal from "@/components/modal/modal";
import Amap from '@/components/Amap'
import moniter from './moniter'
import {schoolDangerRangeConfig} from '@/api/dangerousApi'
export default {
    components: {
        Modal,
        Amap,
        moniter
    },
    props: {
        modelShow: {
            type: Boolean,
            default: false
        },
        student: {
            type: Object,
            default: null
        },
        school: {
            type: Object,
            default: null
        }
    },
    computed: {
       
    },
    filters: {
        random(){
            return Math.random()
        }
    },
    data(){
        return {
            date: [],
            dangerRangeList: [],
          
            selectedDangerIndex: -1,
            monitorList: []
        }
    },
    methods: {
        close(){
            console.log('close')
            this.$emit('close')
        },
        async open(){
            console.log('open')
            console.log(this.student)

            this.dangerRangeList = []
            this.monitorList = []
          
            this.selectedDangerIndex = -1
           
            await this.$refs.amap.init()
            
            this.setDate()
            await this.schoolDangerRangeConfig()
            this.setRange()

            this.getGpsList()
            this.getLastGPS()
        },
        setDate(){
            var time = new Date(this.student.creationTime)
            var year = time.getFullYear()
            var month = time.getMonth() + 1
            var d = time.getDate()
            month = month > 9 ? month: '0' + month
            d = d > 9 ? d: '0' + d
            var str = `${year}-${month}-${d}`

            this.date = [
                str + ' 00:00:00',
                str + ' 23:59:59'
            ]
        },
        setRange(){
            for(var i = 0; i<this.dangerRangeList.length; i++){
                var range = this.dangerRangeList[i]
                if(this.student.schoolDangerRangeConfigId == range.id){
                    this.selectRange(i)
                }
            }
        },
        async schoolDangerRangeConfig(){
            let res = await schoolDangerRangeConfig({
                schoolId: this.school.schoolId
            })
            // id name points
            this.dangerRangeList = res 
            
        },
        selectRange(index){
            if(this.selectedDangerIndex == index){
                // this.selectedDangerIndex = -1
                // this.$refs.amap.clearDangerRange()
                return

            }

            this.selectedDangerIndex = index
            var selectedDangerRange = this.dangerRangeList[index]

            if(selectedDangerRange.monitor_Json){
                this.monitorList = JSON.parse(selectedDangerRange.monitor_Json)
            } else {
                this.monitorList = []
            }

            
            let points = selectedDangerRange.points
            
            if(!points){
                 this.$refs.amap.clearDangerRange()
                return
            }
            
            var pathList = []
            for(var xing of points.split("#")){

                var arr = [];
                for(var p of xing.split("|")){
                    p = p.split(",");
                    var lng = parseFloat(p[0]);
                    var lat = parseFloat(p[1]);
                    arr.push([lng,lat]);
                }
                arr.splice(arr.length - 1,1)
               pathList.push(arr)
            }
            this.$refs.amap.drawDangerRange(pathList)

            
        },
        getGpsList(){
            var params = {
                studentId: this.student.studentId,
            }
            
            if(this.date.length > 0){
                params.beginTime = this.date[0]
                params.endTime = this.date[1]
            }
            
            this.$refs.amap.getGpsList(params)
        },
        getLastGPS(){
            
            this.$refs.amap.getLastGPS(this.student.studentId)
        }
    }
}
</script>
<style scoped>
.form-p{
    position: absolute; top: 0; left: 0;
    height: 74px; width: 100%; background: rgba(0,0,0,0.6);
    box-sizing: border-box; padding: 20px 0 0 30px;
}
.form-n{
    display: inline-block; vertical-align: middle;
    color: #fff; font-size: 16px;
}
.danger-list{
    position: absolute; top: 100px; left: 20px;
    background: rgba(0,0,0,0.6); padding: 20px;
}
.danger-item{
    color: #fff; cursor: pointer; padding: 0 0 20px 0;
}
.middle{
    display: inline-block; vertical-align: middle;
}
.monitor-list{
    width: 294px; height: calc(100% - 74px); overflow-y: scroll; overflow-x: hidden;
      position: absolute; bottom: 0; right: 0;
    background: rgba(0,0,0,0.6);
}

.monitor-count{
    color: #fff; padding: 0 0 0 15px;
}
</style>