<template>
    <div @click="showDetail">
        <div class="box">
            <p class="partName">
                校园报警
            </p>
            <div class="innerBox">   
                <p>
                    最新报警：
                    <span v-if="latestAlarmTime">{{latestAlarmTime | formatDateTime('yyyy-MM-d HH:mm:ss')}}</span>
                     &nbsp;&nbsp;
                    {{latestAlarmSchoolName}}
                </p>
                <p class="secRow">
                    <span>
                        今日报警数：
                        <span class="numColor">
                            {{todayAlarmNum}}
                        </span>
                    </span>
                    <span>
                        接警数：
                        <span class="numColor">
                            {{todayReadedNum}}
                        </span>
                    </span>
                    <span>
                        平均接警时间：
                        <span class="numColor">
                            {{avgReadedTime | timestampZh}}
                        </span>
                    </span>
                </p>
                <div class="barTitle">
                    
                </div>
                <div class="barBox" v-if="isRefresh">
                    <bar :data="latestWeekData" title="最近一周警情："></bar>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import bar from './echarts/bar';
import {getSchoolWarn} from '@/api/county';
import {refreshTime} from '@/config/config'
export default {
    components: {
        bar
    },
    data () {
        return {
            isRefresh:true,
            latestAlarmTime:'',	        //最新报警时间
            latestAlarmSchoolName:'',	    //最新报警学校名称
            todayAlarmNum:0,     	        //今日报警人数
            todayReadedNum:0,	            //今日接警数
            avgReadedTime:0,	        //平均接警时间 以秒为单位
            latestWeekData: [
                {name:"",value:0, color: '#38DEFC'}, 
                {name:"",value:0, color: '#4B9EFB'}, 
                {name:"",value:0, color: '#90EB89'}, 
                {name:"",value:0, color: '#F7B74B'}, 
                {name:"",value:0, color: '#F0702E'},
                {name:"",value:0, color: '#ED342F'},
                {name:"",value:0, color: '#5140F3'}  
            ]
        }
    },
    computed: {
        countyUserInfo () {
          return this.$store.state.user.countyUserInfo
        }
    },
    methods: {
        clear(){
            this.latestAlarmTime =''	        
            this.latestAlarmSchoolName =''	    
            this.todayAlarmNum = 0     	        
            this.todayReadedNum = 0	            
            this.avgReadedTime = 0	        
            this.latestWeekData = [
                {name:"",value:0, color: '#38DEFC'}, 
                {name:"",value:0, color: '#4B9EFB'}, 
                {name:"",value:0, color: '#90EB89'}, 
                {name:"",value:0, color: '#F7B74B'}, 
                {name:"",value:0, color: '#F0702E'},
                {name:"",value:0, color: '#ED342F'},
                {name:"",value:0, color: '#5140F3'}  
            ]
        },
        showDetail () {
            this.$store.state.county.alarmModalShow = true
        },
        getData(){
            
            const params = {
                eduId:this.countyUserInfo.organizationIdStr
            }
            getSchoolWarn(params).then(res => {
                
                if(!res.data){
                    this.clear()
                    return
                }
                let list = [];
                let colorList = ['#38DEFC','#4B9EFB','#90EB89','#F7B74B','#F0702E','#ED342F','#5140F3'];
               
                this.latestAlarmTime = res.data.latestAlarmTime;	       
                this.latestAlarmSchoolName = res.data.latestAlarmSchoolName;	
                this.todayAlarmNum = res.data.todayAlarmNum; 	    
                this.todayReadedNum = res.data.todayReadedNum;       
                this.avgReadedTime = res.data.avgReadedTime;
                list = res.data.latestWeekData;
                

                if(list.length > 0){
                    list.forEach((item,index)=>{
                        item.name = item.day;
                        item.value = item.alarmNum;
                        item.color = colorList[index];
                        delete item.day;
                        delete item.alarmNum;
                    })
                    this.latestWeekData = list;
                    this.isRefresh = true;
                }
                this.isRefresh = true;
            })
        },
        refresh () {
            setTimeout(() => {
                this.getData()
                this.refresh()
            }, refreshTime)
        }
    },
    mounted () {
        this.getData()
        this.refresh()

        this.$store.state.county.callPolice = this
    }
}
</script>

<style scoped>
    *{
        color: #fff;
        font-size: 0.14rem;
    }
    .box{
        padding: 0 0.44rem 0 0.38rem;
        width: 5.04rem;
        height: 2.44rem;
        background-image: url('./img/smallBk.png');
        background-size: 100%;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
    }
    .partName{
        text-align: center;
        font-weight: bold;
        font-size: 0.16rem;
    }
    .innerBox{
        padding: 0.2rem 0 0 0;
    }
    .innerBox >p{
        line-height: 0.28rem;
    }
    .secRow >span{
        display: inline-block;
        width: 30%;
        text-align: left;
        vertical-align: top;
    }
    .secRow >span:last-child{
        display: inline-block;
        width: 38%;
        text-align: left;
    }
    .barBox{
        height: 1.12rem;
        width: 100%;
    }
    .numColor{
        color: #00F9FF;
        font-weight: bold;
    }
</style>