<template>
    <div class="dormChart">
        <div class="view-chart">
            <view-chart 
                :chartId="random" 
                :option="options" 
                :width="w" 
                :height="h">
            </view-chart>
        </div>
        <div class="view-data">
            <div class="item">
                <span class="name"><i class="color1"></i>寄宿生人数</span>
                <span class="number">{{data[0]}}</span>
            </div>
            <div class="item">
                <span class="name"><i class="color2"></i>在寝人数</span>
                <span class="number">{{data[1]}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import viewChart from '@/components/chart/view-chart';
export default {
    name:'dormChart',
    components: {
        viewChart
    },
    props:{
        data:{
            type:Array,
             default:() => {
                return [100,20]
            }
        }
    },
    data(){
        return {
            random:String(Math.random()),
            w:'0.56rem',
            h:'0.56rem',
            option:{
                tooltip: {
                    show: false
                },
                color:['#1069BD','#ED7F26'],
                series: [
                    {
                        name: '在寝比例',
                        type: 'pie',
                        radius: ['90%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'top'
                        },
                        labelLine: {
                            show: false
                        },
                        animationDuration: 2800,
                        animationEasing: "cubicInOut",
                        data:[]
                    }
                ]
            }
        }
    },
    computed:{
        options(){
            var option = this.option;
            option.series[0].data = this.data;
            return option
        }
    }    
}
</script>

<style lang="scss" scoped>
.dormChart{
    display:flex;
    .view-chart{
        width:0.56rem;
        height:0.56rem;
        overflow: hidden;
    }
    .view-data{
        flex: 1;
        .item{
            margin-top:0.03rem;
            span{
                display: inline-block;
                font-size:0.12rem;
                text-align: left;
            }
            .name{
                color:#90A3C8;
                width:0.8rem;
                i{
                    display: inline-block;
                    width:0.08rem;
                    height:0.08rem;
                    border-radius:0.08rem;
                    margin-right:0.08rem;
                }
            }
            .number{
                color:#fff;
            }
        }
    }
}
.color1{
    background-color:#1069BD;
}
.color2{
    background-color:#ED7F26;
}
</style>

